import { TextAreaProps } from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import { ReactNode, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

interface IProps {
    label: ReactNode;
    inputSize?: 'small' | 'normal';
    error?: boolean;
    errorText?: ReactNode;
    imported?: boolean;
}

type Props = IProps & TextAreaProps;

const FloatingTextAreaInput = (props: Props) => {
    const [focus, setFocus] = useState(false);

    const labelClass = useMemo(() => `floating-label${focus || props.value || props.value === 0 ? ' active' : ''}`, [props.value, focus]);

    return (
        <div className={`${props.className !== undefined ? props.className : ''} floating-label-input${props.inputSize === 'small' ? ' small' : ''}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} style={props.style}>
            <TextArea
                {...props}
                className={`input ${props.error ? 'error' : ''}`}
            />

            <span className={'label'}>
                <span className={labelClass}>
                    {props.required && <span style={{ color: "var(--base-error)" }}>{'*'}</span>} {props.label}
                </span>
            </span>
            {
                props.error &&
                <span className={'error-text'}>
                    {props.errorText !== undefined ? props.errorText : <FormattedMessage defaultMessage='This field is required' />}
                </span>
            }
        </div>
    );
};

export default FloatingTextAreaInput;