import { createContext, useState, ReactNode, useContext, useCallback, useRef, useEffect, useMemo } from 'react';
import Network from '../utils/Network';
import { notification } from 'antd';
import { showNotification } from '../utils/utils';
import API from '../utils/API';
import { AxiosError } from 'axios';
import ForceUpdateModal from '../components/common/UpdateModal/ForceUpdateModal';

// Définir le type pour l'état du contexte
interface UpdateContextType {
    loading: boolean;
    refreshVersion: () => void;
}

// Créer le contexte
const UpdateContext = createContext<UpdateContextType | undefined>(undefined);

// Créer un provider pour fournir l'état du message d'erreur
export const UpdateProvider = ({ children }: { children: ReactNode; }) => {
    const [loading, setLoading] = useState(false);
    const [minVersion, setMinVersion] = useState('');

    const [api] = notification.useNotification();

    const interval = useRef<NodeJS.Timeout | undefined>(undefined);

    const shouldUpdate = useCallback((version: string) => {
        const appVersion: string = import.meta.env.VITE_APP_VERSION;

        if (version === undefined)
            return false;

        const splittedAppVersion = appVersion.split(/[.-]/).map(Number);
        const splittedVersion = version?.split(/[.-]/).map(Number);

        return splittedAppVersion < splittedVersion;
    }, []);

    const refreshVersion = useCallback(() => {
        setLoading(true);
        Network.getAppVersion().then(
            (res) => {
                if (setMinVersion)
                    setMinVersion(res.data.min_version);

                if (shouldUpdate(res.data.version)) {
                    api.destroy('update-notification');
                    showNotification('An update is available', 'update', `From version ${import.meta.env.VITE_APP_VERSION} to ${res.data.version}`, 'update-notification');
                }
                setLoading(false);
            },
            () => {
                showNotification('An error occurred while retrieving the app version', 'error');
                setLoading(false);
            }
        );
    }, [api, shouldUpdate]);

    const launchInterval = useCallback((time = 3600000) => {
        //let newInterval: NodeJS.Timer
        if (interval.current === undefined) {
            interval.current = setInterval(() => {
                refreshVersion();
            }, time);
        }
    }, [refreshVersion]);

    useEffect(() => {
        if (interval.current === undefined) {
            refreshVersion();
            launchInterval(900000);
        }

        return () => {
            clearInterval(interval.current);
            interval.current = undefined;
        };
    }, [launchInterval, refreshVersion]);

    useEffect(() => {
        const updateInterceptor = API.interceptors.response.use(
            undefined,
            (error: AxiosError) => {
                if (error.response?.status === 426) {
                    setMinVersion((error.response?.data as any).data.version);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            API.interceptors.response.eject(updateInterceptor);
        };
    });

    const shouldForceUpdate = useMemo(() => {
        const appVersion: string = import.meta.env.VITE_APP_VERSION;

        if (minVersion === undefined)
            return false;

        const splittedAppVersion = appVersion.split(/[.-]/).map(Number);
        const splittedVersion = minVersion?.split(/[.-]/).map(Number);

        return splittedAppVersion < splittedVersion;
    }, [minVersion]);


    return (
        <UpdateContext.Provider value={{ loading, refreshVersion }}>
            {children}
            {shouldForceUpdate && <ForceUpdateModal />}
        </UpdateContext.Provider>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useUpdateContext = (): UpdateContextType => {
    const context = useContext(UpdateContext);
    if (!context) {
        throw new Error('useUpdateContext must be used within an UpdateProvider');
    }
    return context;
};