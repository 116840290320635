import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { useEffect } from "react";
import { ContainerTabsProvider } from "./contexts/containerTabsContext.tsx";
import { UpdateProvider } from "./contexts/updateContext.tsx";

Sentry.init({
    dsn: "https://f07c7da7ed1b642939ae80d396924de8@o293784.ingest.us.sentry.io/4508494871658496",
    integrations: [
        Sentry.reactRouterV7BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [/https?:\/\/(preprod?\.)?admin.webevolutions.ch/],
    allowUrls: [/https?:\/\/(preprod?\.)?admin.webevolutions.ch/]
});

createRoot(document.getElementById('root')!).render(
    <ContainerTabsProvider>
        <UpdateProvider>
            <App />
        </UpdateProvider>
    </ContainerTabsProvider>
);
