const hexToHSL = (hex: string): { h: number; s: number; l: number; } => {
	hex = hex.replace(/^#/, '');

	const r = parseInt(hex.substring(0, 2), 16) / 255;
	const g = parseInt(hex.substring(2, 4), 16) / 255;
	const b = parseInt(hex.substring(4, 6), 16) / 255;

	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h = 0, s = 0;
	const l = (max + min) / 2;

	if (max !== min) {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r: h = (g - b) / d + (g < b ? 6 : 0); break;
			case g: h = (b - r) / d + 2; break;
			case b: h = (r - g) / d + 4; break;
		}
		h *= 60;
	}

	return { h, s, l };
};

const hslToHex = (h: number, s: number, l: number): string => {
	s /= 100;
	l /= 100;

	const c = (1 - Math.abs(2 * l - 1)) * s;
	const x = c * (1 - Math.abs((h / 60) % 2 - 1));
	const m = l - c / 2;

	let r = 0, g = 0, b = 0;

	if (0 <= h && h < 60) { r = c; g = x; b = 0; }
	else if (60 <= h && h < 120) { r = x; g = c; b = 0; }
	else if (120 <= h && h < 180) { r = 0; g = c; b = x; }
	else if (180 <= h && h < 240) { r = 0; g = x; b = c; }
	else if (240 <= h && h < 300) { r = x; g = 0; b = c; }
	else if (300 <= h && h < 360) { r = c; g = 0; b = x; }

	const toHex = (n: number) => {
		const hex = Math.round((n + m) * 255).toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	};

	return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const darker = (hex: string, percent: number): string => {
	const hsl = hexToHSL(hex);
	let l = hsl.l; // `let` pour modifier la luminosité
	const { h, s } = hsl; // `const` pour les valeurs fixes

	l = Math.max(0, l - (percent / 100) * l); // Assombrit la couleur
	return hslToHex(h, s * 100, l * 100);
};

const flattenCssVars = (obj: Record<string, any>, prefix: string = ''): Record<string, string> => {
	const result: Record<string, string> = {};

	Object.entries(obj).forEach(([key, value]) => {
		const formattedKey = key.replace(/_/g, '-');

		// Si la clé est "default", on utilise seulement le préfixe sans concaténation
		const newKey =
			formattedKey === 'default' && prefix ? prefix : prefix ? `${prefix}-${formattedKey}` : formattedKey;

		if (typeof value === 'object' && value !== null) {
			Object.assign(result, flattenCssVars(value, newKey));
		} else {
			result[`--${newKey}`] = value;
		}
	});

	return result;
};

const applyCssVariables = (variables: Record<string, string>): void => {
	const root = document.documentElement;

	Object.entries(variables).forEach(([key, value]) => {
		root.style.setProperty(key, value);
	});
};

const table_warning = '#fff8ea';
const table_error = '#ffecec';

// Children space with '-' and '_' are '-' on variable too
// Synthax "--{name}-{children}"
// For the defaults its only require the name
export const Colors = {
	company_dark: darker('#ffffff', 8),
	warning: {
		default: '#feca57',
		table: table_warning,
		table_dark: darker(table_warning, 4)
	},
	error: {
		default: '#ff6b6b',
		table: table_error,
		table_dark: darker(table_error, 4)
	},
	base: {
		primary: {
			default: '#54a0ff',
			dark: '#2e86de',
		},
		success: {
			default: '#feca57',
			dark: '#ff9f43',
		},
		warning: {
			default: '#1dd1a1',
			dark: '#10ac84',
		},
		error: {
			default: '#ff6b6b',
			dark: '#ee5253',
		},
		gray: {
			default: '#c8d6e5',
			dark: '#8395a7',
		}
	},
	card: {
		background: 'white'
	},
	button: {
		default: {
			color: '#2f373f',
			background: '#e8ebf1'
		},
		primary: {
			color: '#e8ebf1',
			background: '#54a0ff'
		}
	},
	header: '#2f373f',
	border: '#b1b1b1',

	// base color
	red: '#d40f0f',
	yellow: '#e3aa00',
	primary: '#54a0ff',
	primary_dark: '#2e86de',
	warning_dark: '#ff9f43',
	success: '#1dd1a1',
	success_dark: '#10ac84',
	error_dark: '#ee5253',
	gray: '#c8d6e5',
	gray_dark: '#8395a7',
	card_background: '#fffff',

	// button
	button_background: '#e8ebf1',
	button_primary: '#e8ebf1',
	button_primary_background: '#54a0ff',
};

const loadTheme = () => {
	applyCssVariables(flattenCssVars(Colors));
};

export default loadTheme;