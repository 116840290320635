import { CSSProperties, ReactNode } from 'react';
import './styles.css';

interface Props {
    icon: ReactNode,
    title?: string,
    style?: CSSProperties;
}

const Header = (props: Props) => {
    return (
        <div className="header-parent" style={props.style}>
            {props.icon}
            {
                props.title ?
                    <p>{props.title}</p>
                    : null
            }
        </div>
    );
};

export default Header;