import { Col, Row } from "antd";
import Card from "../../../../../../../../common/Card/Card";
import FAIcon from "../../../../../../../../common/FAIcon/FAIcon";
import { FormattedMessage } from "react-intl";

const Billing = () => {
    return (
        <Card
            icon={<FAIcon prefix='fad' name='gear' />}
            title={<FormattedMessage
                defaultMessage={'Configuration'}
            />}
        >
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }}>
                </Col>
            </Row>
        </Card>
    );
};

export default Billing;