import { TableProps } from "antd/es/table";
import Table from "antd/es/table/Table";
import { useMemo } from "react";
import { useWindowSize } from "react-use";

interface Props<T> extends TableProps<T> {
    height?: number;
}

const VirtualTable = <T extends object>(props: Props<T>) => {

    const { height } = useWindowSize();

    const tableHeight = useMemo(() => {
        const tmpHeight = props.height ? height - props.height : height - 360;
        return tmpHeight < 250 ? 250 : tmpHeight;
    }, [height, props.height]);

    return (
        <Table
            {...props}
            scroll={{ x: true, y: tableHeight }}
        />
    );
};

export default VirtualTable;