import FAIcon from "../../common/FAIcon/FAIcon";
import Card from "../../common/Card/Card";
import { Space } from "antd";
import CircleButton from "../../common/CircleButton/CircleButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Client } from "../../../utils/types/clientTypes";
import Network from "../../../utils/Network";
import { ConvertNetworkClients } from "../../../utils/converters";
import { showNotification } from "../../../utils/utils";
import VirtualTable from "../../common/VirtualTable/VirtualTable";
import { ColumnType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const Clients = () => {
	const [clients, setClients] = useState<Client[]>([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const intl = useIntl();

	const getClients = useCallback(() => {
		setLoading(true);
		Network.fetchClients().then(
			(res) => {
				setClients(ConvertNetworkClients(res.data));
				setLoading(false);
			},
			() => {
				showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while retrieving clients' }), 'error');
				setLoading(false);
			}
		);
	}, []);

	useEffect(() => {
		getClients();
	}, [getClients]);

	const columns = useMemo((): ColumnType<Client>[] => {
		return [
			{
				key: 'title',
				dataIndex: 'title',
				title: intl.formatMessage({ defaultMessage: 'Name' })
			},
			{
				key: 'bexiosContactId',
				dataIndex: 'bexiosContactId',
				title: intl.formatMessage({ defaultMessage: 'Bexios contact ID' })
			}
		];
	}, []);

	return (
		<Card
			icon={<FAIcon prefix='fad' name='user-tie-hair' />}
			title='Clients'
			headerElements={[
				<Space>
					<CircleButton
						small
						onClick={getClients}
						icon={<FAIcon prefix='fad' name='rotate' />}
					/>
					<CircleButton
						small
						disabled
						icon={<FAIcon prefix='far' name='plus' />}
					/>
				</Space>
			]}
		>
			<VirtualTable
				loading={loading}
				dataSource={clients}
				columns={columns}
				pagination={false}
				rowKey={(record) => `client-${record.id}`}
				onRow={(record) => ({
					onClick: () => {
						navigate(`${record.id}/companies`);
					}
				})}
			/>
		</Card>
	);
};

export default Clients;