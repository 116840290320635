import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { TabSidebar } from "../components/common/ContainerTabs/types";

interface ContainerTabsContextType {
    tabExtra: { content: ReactNode, key: string; }[];
    tabSidebars: { content: TabSidebar[], key: string; }[];
    addOrUpdateExtra: (content: ReactNode, key: string) => void;
    addOrUpdateSidebars: (content: TabSidebar[], key: string) => void;
}

const ContainerTabsContext = createContext<ContainerTabsContextType | undefined>(undefined);

export const ContainerTabsProvider = ({ children }: { children: ReactNode; }) => {
    const [tabExtra, setTabExtra] = useState<{ content: ReactNode, key: string; }[]>([]);
    const [tabSidebars, setTabSidebars] = useState<{ content: TabSidebar[], key: string; }[]>([]);

    const addOrUpdateExtra = useCallback((content: ReactNode, key: string) => {
        setTabExtra(prevTabExtra => {
            const newExtra = { key, content };
            const extraFound = prevTabExtra.find(extra => extra.key === key);

            if (extraFound) {
                return prevTabExtra.map(extra => extra.key === key ? newExtra : extra);
            }
            return [...prevTabExtra, newExtra];
        });
    }, []);

    const addOrUpdateSidebars = useCallback((content: TabSidebar[], key: string) => {
        setTabSidebars(prevTabSidebars => {
            const newSidebars = { key, content };
            const sidebarsFound = prevTabSidebars.find(sidebars => sidebars.key === key);

            if (sidebarsFound) {
                return prevTabSidebars.map(sidebars => sidebars.key === key ? newSidebars : sidebars);
            }
            return [...prevTabSidebars, newSidebars];
        });
    }, []);

    return (
        <ContainerTabsContext.Provider value={{
            tabExtra,
            tabSidebars,
            addOrUpdateExtra,
            addOrUpdateSidebars
        }}>
            {children}
        </ContainerTabsContext.Provider>
    );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useContainerTabs = (): ContainerTabsContextType => {
    const context = useContext(ContainerTabsContext);
    if (!context) {
        throw new Error('useContainerTabs must be used within an ContainerTabsProvider');
    }
    return context;
};