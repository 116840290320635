import { Image } from "antd";
import './styles.css';
import { useNavigate } from "react-router-dom";

interface Props {
    link: string;
    image: string;
}

const ApplicationCard = (props: Props) => {
    const navigate = useNavigate();

    return (
        <div className='application-card-container' onClick={() => {
            navigate(props.link);
        }}>
            <Image src={props.image} preview={false} />
        </div>
    );
};

export default ApplicationCard;