import { ReactNode } from "react";
import { ConfigProvider as AConfigProvider } from 'antd';
import { Colors } from "../../utils/colors";
import frFR from 'antd/locale/fr_FR';
import enUS from 'antd/locale/en_US';
import deDE from 'antd/locale/de_DE';
import { useLocation } from "react-router-dom";
import { Locale } from "antd/es/locale";

interface Props {
    children?: ReactNode;
}

const ConfigProvider = (props: Props) => {
    const location = useLocation();
    const lang = location.pathname.split('/')[1] ?? "fr";
    let local: Locale;

    switch (lang) {
        case "de": {
            local = deDE;
            break;
        }
        case "en": {
            local = enUS;
            break;
        }
        default: {
            local = frFR;
            break;
        }
    }

    return (
        <AConfigProvider
            locale={local}
            theme={{
                token: {
                    // colorPrimary: "var(--base-primary)"
                },
                components: {
                    Layout: {
                        headerBg: 'white'
                    },
                }
            }}
        >
            {props.children}
        </AConfigProvider>
    );
};

export default ConfigProvider;