import { Tooltip, Button as AButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ReactNode, useMemo } from 'react';
import './styles.css';

interface IProps {
    withoutTooltip?: boolean;
    showTooltip?: boolean;
    icon: ReactNode;
    placement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
}

type Props = IProps & ButtonProps;

const CircleButton = (props: Props) => {

    const button = useMemo(() => {
        return (
            <AButton
                type={props.type ? props.type : "default"}
                className={`circle-button-small ${props.className ? props.className : ''}`}
                title={props.withoutTooltip || props.showTooltip !== true ? props.title : undefined}
                shape="circle"
                disabled={props.disabled}
                {...props}
                size='middle'
            />
        );
    }, [props]);

    return (
        props.disabled || props.withoutTooltip || props.showTooltip !== true ?
            <>
                {button}
            </>
            :
            <Tooltip
                overlayClassName="circle-button-tooltip"
                title={props.title}
                placement={props.placement}>
                {button}
            </Tooltip>
    );
};

export default CircleButton;