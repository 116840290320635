import { UserState } from "./userTypes";
import { WindowState } from "./windowTypes";

//* General *//
export enum StatusType {
    NONE = 'none',
    PENDING = 'pending',
    FULFILLED = 'fulfilled',
    REJECTED = 'rejected',
}

export type Loaded<T> = ({
    status: StatusType.NONE | StatusType.PENDING | StatusType.REJECTED,
    data?: T;
    updatedAt?: string;
} | {
    status: StatusType.FULFILLED,
    data: T;
    updatedAt: string;
});

//* Store *//
export interface StoreState {
    user: UserState;
    window: WindowState;
}