export interface NetworkClient {
    id: number;
    title: string;
    bexios_contact_id: number;
}

export interface Client {
    id: number;
    title: string;
    bexiosContactId: number;
}

export interface NetworkExternalCompany {
    id: number;
    client_id?: number;
    common_id: number;
    title: string;
    type: number;
    billing_frequency: number;
}

export const enum ExternalCompanyTypes {
    SUNKHRONOS = 0
}

export interface ExternalCompany {
    id: number;
    client?: Client;
    commonId: number;
    title: string;
    type: ExternalCompanyTypes;
    billingFrequency: number;
}

export interface NetworkClientDetails {
    client: NetworkClient,
    external_companies: NetworkExternalCompany[];
}

export interface ClientDetails {
    client: Client,
    externalCompanies: ExternalCompany[];
}