import dayjs, { Dayjs } from "dayjs";
import { NetworkUser } from "./types/networkTypes";
import { NetworkSunkhronosCompany, NetworkSunkhronosCompanyUsage, SunkhronosCompany, SunkhronosCompanyUsage } from "./types/applications/sunkhronosTypes";
import { DictionaryString } from "./types/generalTypes";
import { Client, ClientDetails, ExternalCompany, NetworkClient, NetworkClientDetails, NetworkExternalCompany } from "./types/clientTypes";
import { Invoice, NetworkInvoice } from "./types/applications/invoiceTypes";
import { IUser } from "./types/redux/userTypes";
import { SunkhronosCompanyVersions } from "../components/pages/Applications/Sunkhronos/utils/constants";

export const ConvertNetworkUser = (network: NetworkUser): IUser => {
    return {
        id: network.id,
        email: network.email,
        username: network.username,
        firstName: network.first_name,
        lastName: network.last_name,
    };
};

export const ConvertNetworkSunkhronosCompany = (network: NetworkSunkhronosCompany): SunkhronosCompany => {
    return {
        id: network.id,
        name: network.name ? network.name : 'Unknown',
        created: network.created ? dayjs(network.created) : undefined,
        hasUsage: network.has_usage,
        events: network.events,
        admins: network.admins,
        groupAdmins: network.group_admins,
        type: network.type,
        image: network.image,
        expiration: network.expiration ? dayjs(network.expiration) : undefined,
        version: network.version !== undefined && network.version !== null ? network.version : SunkhronosCompanyVersions.NONE.value,
        commonId: network.common_id,

        //TODO: INVOICE MODULE NOT READY
        lastInvoice: network.last_invoice ? dayjs(network.last_invoice) : undefined,
        licenceFrom: network.licence_from ? dayjs(network.licence_from) : undefined,
        licenceTo: network.licence_to ? dayjs(network.licence_to) : undefined,
        contractFrom: network.contract_from ? dayjs(network.contract_from) : undefined,
        contractTo: network.contract_to ? dayjs(network.contract_to) : undefined,
        billingFrequency: network.billing_frequency !== null ? network.billing_frequency : undefined,
        comment: network.comment
        //TODO: INVOICE MODULE NOT READY
    };
};

export const ConvertNetworkSunkhronosCompanies = (network: NetworkSunkhronosCompany[]): SunkhronosCompany[] => {
    return network.map((ncompany) => ConvertNetworkSunkhronosCompany(ncompany));
};


export const ConvertNetworkSunkhronosCompanyUsage = (network: DictionaryString<NetworkSunkhronosCompanyUsage>): DictionaryString<SunkhronosCompanyUsage> => {
    return Object.fromEntries(
        Object.entries(network).map(([key, value]) => [
            String(key),
            {
                hasUsage: value.has_usage,
                usersWithEvents: value.users_with_events,
                usersWithMessages: value.users_with_messages,
                usersWithPosts: value.users_with_posts,
                usersWithEventClocked: value.users_with_event_clocked,
                usersWithAnyActivity: value.users_with_any_activity,
                activeUsers: value.active_users,
                activeAdmins: value.active_admins,
                users: value.users,
                admins: value.admins,
                events: value.events,
                posts: value.posts,
                messages: value.messages,
                plannedEvents: value.planned_events,
                plannedUsersWithEvents: value.planned_users_with_events
            },
        ])
    );
};

export const ConvertNetworkClient = (network: NetworkClient): Client => {
    return {
        id: network.id,
        title: network.title,
        bexiosContactId: network.bexios_contact_id
    };
};

export const ConvertNetworkClients = (network: NetworkClient[]): Client[] => {
    return network.map((nclient) => ConvertNetworkClient(nclient));
};

export const ConvertNetworkExternalCompanies = (network: NetworkExternalCompany[], clients: Client[]): ExternalCompany[] => {
    return network.map((ncompany) => ({
        id: ncompany.id,
        client: clients.find((c) => c.id === ncompany.client_id),
        billingFrequency: ncompany.billing_frequency,
        commonId: ncompany.common_id,
        title: ncompany.title,
        type: ncompany.type
    }));
};

export const ConvertNetworkClientDetails = (network: NetworkClientDetails): ClientDetails => {
    const client = ConvertNetworkClient(network.client);
    return {
        client: client,
        externalCompanies: ConvertNetworkExternalCompanies(network.external_companies, [client])
    };
};

export const ConvertNetworkInvoice = (network: NetworkInvoice, externalCompanies?: ExternalCompany[]): Invoice => {
    return {
        id: network.id,
        bexiosContactId: network.bexios_contact_id,
        externalCompany: externalCompanies && externalCompanies.find((ec) => ec.id === network.external_company_id),
        note: network.note,
        amount: network.amount,
        startDate: dayjs(network.start_date),
        endDate: dayjs(network.end_date),
        created: dayjs(network.created),
        modified: dayjs(network.modified)
    };
};

export const ConvertNetworkInvoices = (network: NetworkInvoice[], externalCompanies?: ExternalCompany[]): Invoice[] => {
    return network.map((ninvoice) => ConvertNetworkInvoice(ninvoice, externalCompanies));
};

export const ConvertDateToNetworkDate = (date: Dayjs): string => {
    return date.format('YYYY-MM-DD');
};

export const ConvertDateTimeToNetworkDateTime = (date: Dayjs): string => {
    return date.format('YYYY-MM-DD HH:mm:ss');
};

export const ConvertSunkhronosCompany = (company: SunkhronosCompany): NetworkSunkhronosCompany => {
    return {
        id: company.id,
        name: company.name,
        created: company.created ? company.created.toISOString() : undefined,
        expiration: company.expiration ? ConvertDateToNetworkDate(company.expiration) : undefined,
        //TODO: INVOICE MODULE NOT READY
        last_invoice: company.lastInvoice ? ConvertDateToNetworkDate(company.lastInvoice) : undefined,
        licence_from: company.licenceFrom ? ConvertDateToNetworkDate(company.licenceFrom) : undefined,
        licence_to: company.licenceTo ? ConvertDateToNetworkDate(company.licenceTo) : undefined,
        contract_from: company.contractFrom ? ConvertDateToNetworkDate(company.contractFrom) : undefined,
        contract_to: company.contractTo ? ConvertDateToNetworkDate(company.contractTo) : undefined,
        billing_frequency: company.billingFrequency !== undefined ? company.billingFrequency : undefined,
        comment: company.comment
        //TODO: INVOICE MODULE NOT READY
    };
};