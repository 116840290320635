import { Tabs, TabsProps } from "antd";
import FAIcon from "../../../common/FAIcon/FAIcon";
import { useCallback, useEffect, useState } from "react";
import useDevice from "../../../../hooks/useSmartphone";
import SkeletonInput from "antd/es/skeleton/Input";
import { ClientDetails } from "../../../../utils/types/clientTypes";
import Network from "../../../../utils/Network";
import { showNotification } from "../../../../utils/utils";
import { ConvertNetworkClientDetails } from "../../../../utils/converters";
import Companies from "./tabs/Companies";
import { useNavigate, useParams } from "react-router-dom";
import Invoices from "./tabs/Invoices";
import { useIntl } from "react-intl";

const TABS = {
    COMPANIES: 'companies',
    INVOICES: 'invoices',
};

const Client = () => {
    const [loading, setLoading] = useState(false);
    const [clientDetails, setClientDetails] = useState<ClientDetails | undefined>(undefined);
    const intl = useIntl();

    const { tab, id } = useParams();
    const navigate = useNavigate();
    const { isSmartphone } = useDevice();

    const getClientDetails = useCallback(() => {
        if (id === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'Client ID is not defined' }), 'error');
            return;
        }

        setLoading(true);

        Network.fetchClientDetails(parseInt(id)).then(
            (res) => {
                setClientDetails(ConvertNetworkClientDetails(res.data));
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }, [id, intl]);

    useEffect(() => {
        getClientDetails();
    }, [getClientDetails]);


    const tabs: TabsProps['items'] = [
        {
            key: TABS.COMPANIES,
            label: <span><FAIcon prefix='fad' name='industry-windows' /> {'Companies'}</span>,
            children: <Companies
                externalCompanies={clientDetails?.externalCompanies}
                loading={loading}
                refresh={getClientDetails}
            />
        },
        {
            key: TABS.INVOICES,
            label: <span><FAIcon prefix='fad' name='file-invoice' /> {'Invoices'}</span>,
            children: <Invoices
                externalCompanies={clientDetails?.externalCompanies}
            />
        },
    ];

    const handleChangeTab = useCallback((tab: string): void => {
        navigate(`/clients/${id}/${tab}`);
    }, [id, navigate]);

    return (
        <Tabs activeKey={tab} items={tabs} onChange={handleChangeTab}
            tabBarExtraContent={isSmartphone ? undefined : {
                left: (
                    <div style={{ marginRight: '2rem' }}>
                        <FAIcon prefix='fad' name='arrow-left' style={{ cursor: 'pointer' }} onClick={() => navigate('/clients')} />
                    </div>
                ),
                right: (
                    <div style={{ marginLeft: '1rem', maxWidth: '300px' }}>
                        <p title={`Client`} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>
                            {
                                loading ?
                                    <SkeletonInput active size='small' style={{ height: 22 }} />
                                    :
                                    clientDetails?.client.title
                            }
                            {` / Client`}
                        </p>
                    </div>
                ),
            }} />
    );
};

export default Client;
