import { CSSProperties, ReactNode } from "react";
import './styles.css';

interface Props {
    children: ReactNode;
    legend: ReactNode;
    style?: CSSProperties;
    legendStyle?: CSSProperties;
}

const Fieldset = (props: Props) => {
    return (
        <fieldset className='fieldset-container' style={props.style}>
            <legend className='fieldset-legend' style={props.legendStyle}>{props.legend}</legend>
            {props.children}
        </fieldset>
    );
};

export default Fieldset;