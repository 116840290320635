import { Button, notification } from "antd";
import { ReloadTimes } from "./constants";
import dayjs, { Dayjs } from "dayjs";
import { Direction } from "../components/common/ContainerTabs/types";
import { SunkhronosCompanyTypes, SunkhronosCompanyVersions } from "../components/pages/Applications/Sunkhronos/utils/constants";

export const emptyCache = () => {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();
    }
};

export const showNotification = (title: string, type: string, description?: string, key?: string) => {
    switch (type) {
        case 'success':
            notification.success({
                message: title,
                description: description,
                placement: 'bottomRight',
                key
            });
            break;
        case 'error':
            notification.error({
                message: title,
                description: description,
                placement: 'bottomRight',
                key
            });
            break;
        case 'info':
            notification.info({
                message: title,
                description: description,
                placement: 'bottomRight',
                key
            });
            break;
        case 'warning':
            notification.warning({
                message: title,
                description: description,
                placement: 'bottomRight',
                key
            });
            break;
        case 'update': {
            const btn = (
                <Button type="default" onClick={() => emptyCache()}>
                    {'Update'}
                </Button>
            );
            notification.info({
                duration: 0,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                icon: null,
                key: 'update-notif-key',
                placement: 'topRight',
                btn
            });
            break;
        }
        default:
            notification.info({
                message: title,
                description: description,
                placement: 'bottomRight',
                key
            });
            break;
    }
};

export const canReload = (speed: ReloadTimes, date?: string) => (date === undefined || dayjs().diff(dayjs(date), "minutes") >= speed);

export const isNullOrEmpty = (array: Array<any> | undefined | null | string): array is null | undefined | [] => {
    return array === undefined || array === null || array.length === 0;
};

export const getMimeType = (src: string): string | undefined => {
    const extension = src.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'webp':
            return 'image/webp';
        case 'avif':
            return 'image/avif';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'svg':
            return 'image/svg+xml';
        default:
            return undefined; // Si l'extension n'est pas reconnue
    }
};

export const checkScroll = (el: any, d: Direction) => {
    const direction = (d === Direction.Vertical) ? 'scrollTop' : 'scrollLeft';
    try {
        let result = !!el[direction];

        if (!result) {
            el[direction] = 1;
            result = !!el[direction];
            el[direction] = 0;
        }
        return result;
    } catch {
        return false;
    }
};

export const isNullOrUndefined = (value: any) => value === undefined || value === null;

export const getCompanyTypeLabel = (value: number): string => {
    const entry = Object.values(SunkhronosCompanyTypes).find(type => type.value === value);
    return entry ? entry.label : 'Unknown';
};

export const getCompanyVersionLabel = (value: number): string => {
    const entry = Object.values(SunkhronosCompanyVersions).find(type => type.value === value);
    return entry ? entry.value === SunkhronosCompanyVersions.NONE.value ? '' : entry.label : 'Unknown';
};

export const isDateExpired = (date: Dayjs, expiration: Dayjs) => {
    return date.isBefore(expiration);
};