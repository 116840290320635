import { Button, Input, Image } from "antd";
import { useCallback, useState } from "react";
import Network from "../../../utils/Network";
import { showNotification } from "../../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import './styles.css';
import logo from '../../../assets/logo_square.jpg';
import { useIntl } from "react-intl";

const Login = () => {
    const location = useLocation();
    const lang = location.pathname.split('/')[1];

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const intl = useIntl();

    const handleLogin = useCallback(() => {
        setLoading(true);
        Network.login(username, password).then(
            () => {
                navigate(`${lang}/applications/sunkhronos/companies`);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'Incorrect username or password' }), 'error');
                setLoading(false);
            }
        );
    }, [intl, lang, navigate, password, username]);


    return (
        <div className='login'>
            <div className='container'>
                <Image
                    src={logo}
                    preview={false}
                    className='logo'
                />
                <Input
                    placeholder={intl.formatMessage({ defaultMessage: "Username" })}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onPressEnter={handleLogin}
                />
                <Input
                    placeholder={intl.formatMessage({ defaultMessage: "Password" })}
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onPressEnter={handleLogin}
                />
                <Button type='primary' onClick={handleLogin} loading={loading}>{intl.formatMessage({ defaultMessage: "Login" })}</Button>
            </div>
        </div>
    );
};

export default Login;