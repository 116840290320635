import { Badge, Button, Divider, Drawer, Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FAIcon from "../FAIcon/FAIcon";
import SpaceContent from "../SpaceContent/SpaceContent";
import { Direction, TabItem, TabSidebar } from "./types";
import { checkScroll, isNullOrEmpty } from "../../../utils/utils";
import CircleButton from "../CircleButton/CircleButton";
import useWindow from "../../../hooks/useWindow";
import { TabSidebarType } from "./types";
import CheckMobile from "../CheckMobile/checkMobile";
import useDevice from "../../../hooks/useSmartphone";
import SidebarContent from "./SidebarContent";
import { useWindowSize } from "react-use";
import './styles.css';

interface Props {

    tabItems?: TabItem[];
    tabExtra?: ReactNode;
    tabSidebars?: TabSidebar[];

    backUrl?: string;
    baseUrl: string;

    bottomTagText?: ReactNode;

    place?: string;
}

const ContainerTabs = (props: Props) => {
    // #region Hooks
    const navigate = useNavigate();
    const { height } = useWindowSize();
    const window = useWindow();
    const { isSmartphone, isTablet } = useDevice();
    const { tab } = useParams();

    const location = useLocation();
    const lang = location.pathname.split('/')[1] ?? "fr";
    // #endregion

    // #region Lifecycle
    //* Mount
    useEffect(() => {
        const anchor = document.getElementById('anchor');
        if (anchor) anchor.scrollIntoView();
    }, []);
    // #endregion

    const sidebarsButtons = useMemo(() => {
        if (!isNullOrEmpty(props.tabSidebars)) {
            return props.tabSidebars.map((s, idx) => (

                <CircleButton
                    key={`sidebar-button-${s.type}-${idx}`}
                    type={window.sidebarType === s.type ? "primary" : "default"}
                    onClick={() => window.updateSidebarType(s.type === window.sidebarType ? TabSidebarType.NONE : s.type)}
                    title={s.button.title}
                    icon={
                        <>
                            <Badge dot={s.hasBadge} >
                                {s.button.icon}
                            </Badge>
                        </>
                    }
                />
            ));
        }
    }, [props.tabSidebars, window]);

    const hasSidebar = useMemo(() => !isNullOrEmpty(props.tabSidebars), [props.tabSidebars]);

    const foundSidebar = useMemo(() => {
        return hasSidebar ? props.tabSidebars?.find(s => s.type === window.sidebarType) : undefined;
    }, [hasSidebar, props.tabSidebars, window.sidebarType]);

    const sidebarContent = useMemo(() => hasSidebar && foundSidebar && foundSidebar.content, [foundSidebar, hasSidebar]);
    const sidebarTitle = useMemo(() => hasSidebar && foundSidebar && foundSidebar.titleBar?.title, [foundSidebar, hasSidebar]);
    const sidebarIcon = useMemo(() => hasSidebar && foundSidebar && foundSidebar.titleBar?.icon, [foundSidebar, hasSidebar]);
    const sidebarExtra = useMemo(() => hasSidebar && foundSidebar && foundSidebar.titleBar?.extra, [foundSidebar, hasSidebar]);
    const openSidebar = useMemo(() => hasSidebar && foundSidebar !== undefined && window.sidebarType !== TabSidebarType.NONE, [foundSidebar, hasSidebar, window.sidebarType]);

    const hasScroll = useMemo(() => checkScroll(document.getElementById("container-tabs-content-wrapper"), Direction.Vertical), []);

    const containerHeight = useMemo(() => {
        return height - 200;
    }, [height]);


    // #region Handlers
    const handleNavigation = useCallback((to?: string) => navigate(`/${lang}/${props.baseUrl}/${to}`), [lang, navigate, props.baseUrl]);
    // #region

    // #region Render
    return (
        <Content className='container-tabs-layout'>
            <Tabs
                className='container-tabs'
                tabBarStyle={{ paddingLeft: '5px', paddingRight: '5px' }}
                animated
                activeKey={tab}
                onChange={(e) => {
                    handleNavigation(e);
                }}
                items={props.tabItems?.map(item => {
                    return {
                        ...item,
                        children: (
                            <CheckMobile hideOnMobile={item.hideMobile}>
                                <div style={{ position: 'relative', paddingLeft: isSmartphone ? '5px' : undefined, height: `${isTablet ? (containerHeight - 45) : containerHeight}px`, maxHeight: `${isTablet ? (containerHeight - 45) : containerHeight}px`, overflow: 'hidden' }} className={`container-tabs-main ${hasSidebar ? 'filters' : 'nofilters'}`}>
                                    <div className={`container-tabs-main-table-container ${hasSidebar ? 'filters' : 'nofilters'}`}>
                                        <div id='container-tabs-content-wrapper' style={{ paddingRight: hasScroll || hasSidebar ? '5px' : undefined }}>
                                            {item.children}
                                        </div>
                                    </div>
                                    {
                                        hasSidebar ?
                                            isTablet ?
                                                <Drawer
                                                    closable={false}
                                                    style={{ position: 'absolute' }}
                                                    drawerStyle={{ backgroundColor: "var(--base-gray)" }}
                                                    onClose={() => window.updateSidebarType(TabSidebarType.NONE)}
                                                    width={"100%"}
                                                    getContainer={false}
                                                    open={isTablet && openSidebar}
                                                    footer={[
                                                        <SpaceContent key={"container-tabs-drawer-footer"}>
                                                            <Button type='primary' onClick={() => window.updateSidebarType(TabSidebarType.NONE)}>
                                                                {'Show results'}
                                                            </Button>
                                                        </SpaceContent>
                                                    ]}
                                                    footerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                >
                                                    <div style={{ padding: '15px 10px' }}>
                                                        <SidebarContent content={sidebarContent} title={sidebarTitle} icon={sidebarIcon} extra={sidebarExtra} />
                                                    </div>
                                                </Drawer>
                                                :
                                                <div className={`container-tabs-main-filters ${openSidebar ? 'filters' : 'nofilters'}`}>
                                                    <SidebarContent content={sidebarContent} title={sidebarTitle} icon={sidebarIcon} extra={sidebarExtra} />
                                                </div>
                                            : null
                                    }
                                </div>
                            </CheckMobile>
                        )
                    };
                })}
                tabBarExtraContent={
                    {
                        left: (
                            props.backUrl ? (
                                <div style={{ marginRight: '2rem' }}>
                                    <FAIcon prefix='fad' name='arrow-left' style={{ cursor: 'pointer' }} onClick={() => props.backUrl && navigate(`/${lang}/${props.backUrl}`)} />
                                </div>
                            )
                                :
                                <></>
                        ),
                        right: (
                            <SpaceContent>
                                {props.tabExtra}
                                {
                                    sidebarsButtons && props.tabExtra ?
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Divider dashed style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.8)' }} type={'vertical'} />
                                            </div>
                                            {sidebarsButtons}
                                        </>
                                        :
                                        <></>
                                }
                            </SpaceContent>
                        )
                    }
                }
            />
            <div className='container-tabs-label'>
                {`Sunkhronos${props.place ? ` / ${props.place}` : ''}`}
            </div>
        </Content>
    );
    // #endregion
};

export default ContainerTabs;
