import { Col, Row } from "antd";
import FAIcon from "../../common/FAIcon/FAIcon";
import ApplicationCard from "../../common/ApplicationCard/ApplicationCard";
import SunkhronosLogo from '../../../assets/sunkhronos.png';
import Card from "../../common/Card/Card";
import { FormattedMessage } from "react-intl";

const Applications = () => {
    const ApplicationsList = () => {
        return (
            <Card
                icon={<FAIcon prefix='fad' name='grid-2-plus' />}
                title={<FormattedMessage
                    defaultMessage={'Applications'}
                />}
            >
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }}>
                        <ApplicationCard link='sunkhronos/companies' image={SunkhronosLogo} />
                    </Col>
                </Row>
            </Card>
        );
    };

    return (
        <>
            <ApplicationsList />
        </>
    );
};

export default Applications;