import { Select, TreeSelect } from "antd";
import { SelectProps, TreeSelectProps } from "antd/lib";
import React, { ReactNode, useMemo, useState } from "react";
import { FloatingSelectTypes } from "./types";
import { FormattedMessage } from "react-intl";

type IProps = {
    label: ReactNode;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    inputSize?: 'small' | 'normal';
    className?: string;
    style?: React.CSSProperties;
    error?: boolean;
    errorText?: ReactNode;
    imported?: boolean;
};
type Props = IProps & (SelectProps | TreeSelectProps);

const FloatingSelectInput = (props: Props) => {
    const [focus, setFocus] = useState(false);

    const Field: any = useMemo(() => {
        switch (props.type) {
            case FloatingSelectTypes.TREE:
                return TreeSelect;
            case FloatingSelectTypes.SELECT:
            default:
                return Select;
        }
    }, [props.type]);


    const labelClass = useMemo(() => `floating-label${focus || (Array.isArray(props.value) && props.value.length > 0) || (!Array.isArray(props.value) && props.value) ? ' active' : ''}`, [props.value, focus]);

    return (
        <div className={`${props.className !== undefined ? props.className : ''} floating-label-input${props.inputSize === 'small' ? ' small' : ''}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} style={props.style}>
            <Field
                className={`input`}
                style={{ width: '100%', textAlign: 'left' }}
                {...props}
            />
            <span className={'label'}>
                <span className={labelClass}>
                    {props.required && <span style={{ color: "var(--base-error)" }}>{'*'}</span>} {props.label}
                </span>
            </span>
            {
                props.error &&
                <span className={'error-text'}>
                    {props.errorText !== undefined ? props.errorText : <FormattedMessage defaultMessage='This field is required' />}
                </span>
            }
        </div>
    );
};

type OProps = {
    label?: string;
    value?: any;
    key: React.Key;
    children?: any;
};

export const FloatingSelectOption = (props: OProps) => {
    return (
        <Select.Option
            {...props}
        >
            {props.children}
        </Select.Option>
    );
};

export default FloatingSelectInput;