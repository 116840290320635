import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, Modal, Row } from "antd";
import { SunkhronosCompany } from "../../../../../../../utils/types/applications/sunkhronosTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { showNotification } from "../../../../../../../utils/utils";
import Network from "../../../../../../../utils/Network";
import { ConvertDateToNetworkDate, ConvertNetworkSunkhronosCompany, ConvertSunkhronosCompany } from "../../../../../../../utils/converters";
import dayjs, { Dayjs } from "dayjs";
import { cloneDeep } from "lodash";
import FloatingDatePickerInput from "../../../../../../common/Inputs/Floating/FloatingDatePicker";
import FloatingSelectInput from "../../../../../../common/Inputs/Floating/FloatingSelect";
import Fieldset from "../../../../../../common/Fieldset/Fieldset";
import FAIcon from "../../../../../../common/FAIcon/FAIcon";
import FloatingTextAreaInput from "../../../../../../common/Inputs/Floating/FloatingTextArea";
import { ContractTypeModeType } from '../../../utils/types';
import { SunkhronosCompanyBillingFrequencies } from '../../../utils/constants';

interface Props {
    open: boolean;
    onCancel: (companies?: SunkhronosCompany) => void;
    selectedCompany?: SunkhronosCompany;
    selectedDate: Dayjs;
}

const EditCompanyModal = (props: Props) => {
    const intl = useIntl();

    const SunkhronosCompanyBillingFrequenciesMessages = defineMessages<ContractTypeModeType>({
        MONTHLY: { defaultMessage: "Monthly (x1 month)" },
        QUARTERLY: { defaultMessage: "Quarterly (x3 months)" },
        HALF_YEARLY: { defaultMessage: "Half-yearly (x6 months)" },
        YEARLY: { defaultMessage: "Yearly (x12 months)" },
    });

    const [selectedCompany, setSelectedCompany] = useState<SunkhronosCompany | undefined>(props.selectedCompany);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.selectedCompany !== undefined)
            setSelectedCompany(props.selectedCompany);
    }, [props.selectedCompany]);

    const selectedCompanyLicenceErrors = useMemo(() => {
        return {
            startDateError: selectedCompany ? selectedCompany.licenceFrom?.isAfter(selectedCompany.licenceTo) : false,
            startDateMissing: selectedCompany ? selectedCompany.licenceFrom === undefined && selectedCompany.licenceTo !== undefined : false
        };
    }, [selectedCompany]);

    const selectedCompanyContractErrors = useMemo(() => {
        return {
            startDateError: selectedCompany ? selectedCompany.contractFrom?.isAfter(selectedCompany.contractTo) : false,
            startDateMissing: selectedCompany ? selectedCompany.contractFrom === undefined && selectedCompany.contractTo !== undefined : false
        };
    }, [selectedCompany]);

    const handleSave = useCallback(() => {
        if (!selectedCompany) {
            showNotification(intl.formatMessage({ defaultMessage: 'Selected company is undefined' }), 'error');
            return;
        }

        setLoading(true);
        Network.updateSunkhronosCompany(ConvertSunkhronosCompany(selectedCompany), ConvertDateToNetworkDate(props.selectedDate)).then(
            (res) => {
                props.onCancel(ConvertNetworkSunkhronosCompany(res.data));
                setLoading(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the company' }), 'error');
                setLoading(false);
            }
        );
    }, [intl, props, selectedCompany]);

    return (
        <Modal
            open={props.open}
            onCancel={() => props.onCancel()}
            title={<><FAIcon prefix='fad' name='industry-windows' /> <>{`Editing company: ${selectedCompany?.name}`}</></>}
            closable={false}
            footer={[
                <Button onClick={() => props.onCancel()} disabled={loading}>{<FormattedMessage defaultMessage={'Cancel'} />}</Button>,
                <Button type='primary' onClick={handleSave} loading={loading}>{<FormattedMessage defaultMessage={'Save'} />}</Button>
            ]}
        >
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }}>
                    <Fieldset legend={<><FAIcon prefix='fad' name='file-certificate' /> {<FormattedMessage
                        defaultMessage={'Licence'}
                    />}</>}>
                        <Row gutter={[10, 30]} style={{ marginTop: '1rem' }}>
                            <Col xs={{ span: 12 }}>
                                <FloatingDatePickerInput
                                    label={<FormattedMessage
                                        defaultMessage={"Licence from date"}
                                    />}
                                    value={selectedCompany?.licenceFrom}
                                    allowClear={false}
                                    disabled={loading}
                                    error={selectedCompanyLicenceErrors.startDateError || selectedCompanyLicenceErrors.startDateMissing}
                                    errorText={selectedCompanyLicenceErrors.startDateError ? intl.formatMessage({ defaultMessage: 'The start date must be before the end date' }) :
                                        selectedCompanyLicenceErrors.startDateMissing ? intl.formatMessage({ defaultMessage: 'This field is required' }) : intl.formatMessage({ defaultMessage: 'Unknown error' })}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.licenceFrom = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <FloatingDatePickerInput
                                    label={<FormattedMessage
                                        defaultMessage={'Licence to date'}
                                    />}
                                    value={selectedCompany?.licenceTo}
                                    allowClear={false}
                                    disabled={loading}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.licenceTo = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Fieldset legend={<><FAIcon prefix='fad' name='file-invoice' />{<FormattedMessage
                        defaultMessage={'Invoice'}
                    />} </>}>
                        <Row gutter={[10, 30]} style={{ marginTop: '1rem' }}>
                            <Col xs={{ span: 12 }}>
                                <FloatingSelectInput
                                    label={<FormattedMessage
                                        defaultMessage={'Invoice frequency'}
                                    />}
                                    value={selectedCompany?.billingFrequency}
                                    allowClear
                                    options={Object.keys(SunkhronosCompanyBillingFrequencies).map(i => ({
                                        value: SunkhronosCompanyBillingFrequencies[i as ContractTypeModeType],
                                        label: intl.formatMessage(SunkhronosCompanyBillingFrequenciesMessages[i as ContractTypeModeType])
                                    }))}
                                    onChange={(e: number) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.billingFrequency = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <FloatingDatePickerInput
                                    label={<FormattedMessage
                                        defaultMessage={'Last invoice date'}
                                    />}
                                    value={selectedCompany?.lastInvoice}
                                    allowClear={false}
                                    disabled={loading}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.lastInvoice = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Fieldset legend={<><FAIcon prefix='fad' name='file-contract' /> {'Contract'}</>}>
                        <Row gutter={[10, 30]} style={{ marginTop: '1rem' }}>
                            <Col xs={{ span: 12 }}>
                                <FloatingDatePickerInput
                                    label={<FormattedMessage
                                        defaultMessage={'Contract from date'}
                                    />}
                                    value={selectedCompany?.contractFrom}
                                    allowClear={false}
                                    disabled={loading}
                                    error={selectedCompanyContractErrors.startDateError || selectedCompanyContractErrors.startDateMissing}
                                    errorText={selectedCompanyContractErrors.startDateError ? intl.formatMessage({ defaultMessage: 'The start date must be before the end date' })
                                        : selectedCompanyContractErrors.startDateMissing ? intl.formatMessage({ defaultMessage: 'This field is required' }) : intl.formatMessage({ defaultMessage: 'Unknown error' })}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.contractFrom = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <FloatingDatePickerInput
                                    label={<FormattedMessage
                                        defaultMessage={'Contract to date'}
                                    />}
                                    value={selectedCompany?.contractTo}
                                    allowClear={false}
                                    disabled={loading}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.contractTo = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Fieldset legend={<><FAIcon prefix='fad' name='pen-field' /> {'Extra'}</>}>
                        <Row gutter={[10, 30]} style={{ marginTop: '1rem' }}>
                            <Col xs={{ span: 24 }}>
                                <FloatingDatePickerInput
                                    label={<FormattedMessage
                                        defaultMessage={'Expiration date'}
                                    />}
                                    value={selectedCompany?.expiration ? dayjs(selectedCompany?.expiration) : undefined}
                                    disabled={loading}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.expiration = e;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <FloatingTextAreaInput
                                    label={<FormattedMessage
                                        defaultMessage={'Comments'}
                                    />}
                                    rows={6}
                                    value={selectedCompany?.comment}
                                    disabled={loading}
                                    onChange={(e) => {
                                        const tmpSelectedCompany = cloneDeep(selectedCompany);
                                        if (tmpSelectedCompany)
                                            tmpSelectedCompany.comment = e.target.value;
                                        setSelectedCompany(tmpSelectedCompany);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Fieldset>
                </Col>
            </Row>
        </Modal>
    );
};

export default EditCompanyModal;