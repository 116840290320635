export const enum FloatingPickerTypes {
    TIMEPICKER = 'timepicker',
    DATEPICKER = 'datepicker'
}

export const enum FloatingSelectTypes {
    TREE = 'tree',
    SELECT = 'select'
}

export interface FloatingSelectOption {
    label: string | JSX.Element;
    value: number | string;
}