export const SunkhronosCompanyTypes = {
    NORMAL_OLD: { value: 1, label: "Normal (Old)" },
    CCNT_HOTELS: { value: 2, label: "CCNT" },
    NORMAL: { value: 3, label: "Normal" },
    CCT_SECURITY: { value: 4, label: "CCT" }
} as const;

export const SunkhronosCompanyVersions = {
    NONE: { value: -1, label: "None" },
    SWISS_SKI_SCHOOL: { value: 0, label: "Swisskischool" },
    SKI_SCHOOL_ALLIANCE: { value: 1, label: "Ski School Alliance" },
    BOOKING_SPORT: { value: 2, label: "Booking Sport" },
    BOOKING_FORMATION: { value: 3, label: "Booking Formation" }
} as const;

export const SunkhronosCompanyBillingFrequencies = {
    MONTHLY: 1,
    QUARTERLY: 3,
    HALF_YEARLY: 6,
    YEARLY: 12,
};
