import { ExternalCompany, ExternalCompanyTypes } from "../../../../../utils/types/clientTypes";
import Card from "../../../../common/Card/Card";
import FAIcon from "../../../../common/FAIcon/FAIcon";
import VirtualTable from "../../../../common/VirtualTable/VirtualTable";
import { showNotification } from "../../../../../utils/utils";
import { useMemo } from "react";
import { ColumnType } from "antd/es/table";
import CircleButton from "../../../../common/CircleButton/CircleButton";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { values } from "lodash";

interface Props {
    externalCompanies?: ExternalCompany[];
    loading: boolean;
    refresh: () => void;
}

const Companies = (props: Props) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const columns = useMemo((): ColumnType<ExternalCompany>[] => {
        return [
            {
                key: 'title',
                dataIndex: 'title',
                title: intl.formatMessage({ defaultMessage: 'Name' })
            },
            {
                key: 'type',
                dataIndex: 'type',
                title: intl.formatMessage({ defaultMessage: 'Company type' }),
                render: (_, record) => {
                    switch (record.type) {
                        case ExternalCompanyTypes.SUNKHRONOS:
                            return intl.formatMessage({ defaultMessage: 'Sunkhronos' });
                        default:
                            return intl.formatMessage({ defaultMessage: 'Unknown type' });
                    }
                }
            },
            {
                key: 'billingFrequency',
                dataIndex: 'billingFrequency',
                title: intl.formatMessage({ defaultMessage: 'Biling frequency' }),
                // render: (_, record) => intl.formatMessage({ defaultMessage: `Every {nbr_month} months` }, { nbr_month: record.billingFrequency })
                render: (_, record) => intl.formatMessage({ defaultMessage: "{count, plural, one{Every {count} month} other{Every {count} months}}" }, { count: record.billingFrequency })
            }
        ];
    }, [intl]);

    return (
        <Card
            icon={<FAIcon prefix='fad' name='industry-windows' />}
            title={<FormattedMessage defaultMessage={"Companies"} />}
            headerElements={[
                <CircleButton
                    small
                    onClick={props.refresh}
                    loading={props.loading}
                    icon={<FAIcon prefix='fad' name='rotate' />}
                />
            ]}
        >
            <VirtualTable
                dataSource={props.externalCompanies}
                pagination={false}
                columns={columns}
                rowKey={(record) => `client-company-${record.id}`}
                onRow={(record) => ({
                    onClick: () => {
                        switch (record.type) {
                            case ExternalCompanyTypes.SUNKHRONOS:
                                navigate(`/applications/sunkhronos/companies/${record.commonId}/usage-table`);
                                break;
                            default:
                                showNotification(intl.formatMessage({ defaultMessage: 'Unknown company type' }), 'error');
                        }
                    }
                })}
            />
        </Card>
    );
};

export default Companies;