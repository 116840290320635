import { DatePicker, TimePicker } from "antd";
import { Dayjs } from "dayjs";
import React, { ChangeEventHandler, ReactNode, useMemo, useState } from "react";
import { FloatingPickerTypes } from "./types";
import { isArray } from 'lodash';
import { FormattedMessage } from "react-intl";

interface Props {
    label: ReactNode | ReactNode[];
    onChange: ChangeEventHandler<HTMLInputElement> & ChangeEventHandler<HTMLTextAreaElement> & ((value: any) => void);
    value?: any;
    type?: string;
    required?: boolean;
    allowClear?: boolean;
    error?: boolean;
    errorText?: ReactNode;
    format?: string;
    rangePicker?: boolean;
    disabled?: boolean;
    picker?: 'month' | 'week' | 'quarter' | 'year';
    inputSize?: 'small' | 'normal';
    loading?: boolean;
    className?: string;
    style?: React.CSSProperties;
    autoFocus?: boolean;
    disabledDate?: (current: Dayjs) => boolean;
    onFocus?: () => void;
    onPressEnter?: () => void;
    onBlur?: () => void;
}

const FloatingDatePickerInput = (props: Props) => {
    const [focus, setFocus] = useState(false);

    let Field = undefined;

    switch (props.type) {
        case FloatingPickerTypes.TIMEPICKER:
            Field = TimePicker;
            break;
        case FloatingPickerTypes.DATEPICKER:
        default:
            Field = DatePicker;
    }


    const labelClass = useMemo(() => `floating-label${focus || (Array.isArray(props.value) && props.value.length > 0) || (!Array.isArray(props.value) && props.value) ? ' active' : ''}`, [props.value, focus]);


    return (
        <div className={`${props.className !== undefined ? props.className : ''} floating-label-input${props.inputSize === 'small' ? ' small' : ''}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} style={props.style}>
            {
                props.rangePicker ?
                    <Field.RangePicker
                        className={`input ${props.error ? 'error' : ''}`}
                        placeholder={['', '']}
                        {...props}
                    />
                    :
                    <Field
                        className={`input ${props.error ? 'error' : ''}`}
                        placeholder=''
                        {...props}
                    />
            }


            <span className={'label'}>
                <span className={labelClass}>
                    {
                        props.label !== undefined && props.label !== null ?
                            props.rangePicker && isArray(props.label) ?
                                props.required ?
                                    <>
                                        <span><span style={{ color: "var(--base-error)" }}>{`* `}</span>{props.label[0]}</span>
                                        <span style={{ position: 'absolute', left: 'calc(50% + 6px)' }}><span style={{ color: "var(--base-error)" }}>{`* `}</span>{props.label[1]}</span>
                                    </>
                                    :
                                    <>
                                        <span>{props.label[0]}</span>
                                        <span style={{ position: 'absolute', left: 'calc(50% + 6px)' }}>{props.label[1]}</span>
                                    </>
                                :
                                props.required ?
                                    <span style={{ color: "var(--base-error)" }}>{`* ${props.label}`}</span>
                                    :
                                    <span>{props.label}</span>
                            :
                            undefined
                    }
                </span>
            </span>
            {
                props.error &&
                <span className={'error-text'}>
                    {props.errorText !== undefined ? props.errorText : <FormattedMessage defaultMessage='This field is required' />}
                </span>
            }
        </div>
    );
};

export default FloatingDatePickerInput;