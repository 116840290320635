import { useParams } from "react-router-dom";
import FAIcon from "../../../common/FAIcon/FAIcon";
import Companies from "./tabs/Companies/Companies";
import { useMemo } from "react";
import ContainerTabs from "../../../common/ContainerTabs/ContainerTabs";
import { useContainerTabs } from "../../../../contexts/containerTabsContext";
import { TabItem } from "../../../common/ContainerTabs/types";
import Details from "./tabs/Details/Details";
import { FormattedMessage } from "react-intl";

const TABS = {
    DETAILS: 'details',
    COMPANIES: 'companies',
    CLIENTS: 'clients',
};

const Sunkhronos = () => {
    const { tab } = useParams();
    const { tabExtra, tabSidebars, addOrUpdateExtra, addOrUpdateSidebars } = useContainerTabs();

    const tabItems = useMemo((): TabItem[] => {
        return [
            {
                key: TABS.DETAILS,
                label: <span><FAIcon prefix='fad' name='circle-info' /> {<FormattedMessage defaultMessage={'Details'} />} </span>,
                disabled: true,
                children: <Details />
            },
            {
                key: TABS.COMPANIES,
                label: <span><FAIcon prefix='fad' name='industry-windows' /> {<FormattedMessage defaultMessage={'Companies'} />} </span>,
                children: <Companies addOrUpdateExtra={addOrUpdateExtra} addOrUpdateSidebars={addOrUpdateSidebars} keyLink={TABS.COMPANIES} />
            },
        ];
    }, [addOrUpdateExtra, addOrUpdateSidebars]);

    return (
        <ContainerTabs
            baseUrl='applications/sunkhronos'
            backUrl='applications'
            tabItems={tabItems}
            tabExtra={tabExtra.find(item => item.key === tab)?.content}
            tabSidebars={tabSidebars.find(item => item.key === tab)?.content}
            bottomTagText={<FormattedMessage defaultMessage={'Sunkhronos Company'} />}
        />
    );
};

export default Sunkhronos;