import { IconName, IconPrefix } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import FontAwesome from "./fontawesome";
import { IconPacks } from "./IconPacks";

type TypeOrString<T> = T | (string & {}); // To allow any string while keeping eslint autocomplete
interface P extends Omit<FontAwesomeIconProps, 'icon'> {
  pack?: IconPacks;
  prefix: TypeOrString<IconPrefix>;
  name: TypeOrString<IconName>;
}

class FAIcon extends Component<P> {

  constructor(props: P) {
    super(props);
  }

  render() {
    const { pack, prefix, name, ...otherProps } = this.props;
    const icon = pack ? FontAwesome.GetIcon(prefix, name) : FontAwesome.GetIcon(prefix, name);

    if (icon) {
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={icon.definition}
        />
      );
    }
    return (<></>);
  }
}

export default FAIcon;