import { CSSProperties, ReactNode } from 'react';
import Header from '../Header/Header';
import Title from '../Title/Title';
import './styles.css';

interface Props {
    className?: string;
    containerClassName?: string;
    style?: CSSProperties;
    icon: React.ReactNode;
    title: string | ReactNode;
    children: ReactNode;
    headerElements?: ReactNode[];
    containerStyle?: CSSProperties;
}

const Card = (props: Props) => {
    return (
        <div style={props.style} className={`card-parent ${props.className ? props.className : ''}`}>
            <div className="card-header">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Header icon={props.icon} />
                    <Title>{props.title}</Title>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '5px' }}>
                    {props.headerElements}
                </div>
            </div>
            <div className="card-divider" />
            <div className={`card-content ${props.containerClassName ? props.containerClassName : ''}`} style={props.containerStyle}>
                {props.children}
            </div>
        </div>
    );
};

export default Card;