import React from 'react';
import { isMobile } from 'react-device-detect';
import './checkMobile.css';
import NoMobilePng from './no-mobile.png';
import NoMobileWebp from './no-mobile.webp';
import { useWindowSize } from 'react-use';
import ResponsiveImage from '../ResponsiveImage/responsiveImage';
import { FormattedMessage, useIntl } from 'react-intl';


interface CheckMobileProps {
    hideOnMobile?: boolean;
    minWidth?: undefined;
    children: React.ReactElement;
}

const CheckMobile: React.FC<CheckMobileProps> = ({ children, hideOnMobile, minWidth }) => {
    const { width } = useWindowSize();
    const intl = useIntl();

    return (
        hideOnMobile !== undefined ?
            hideOnMobile && isMobile ?
                <div className='no-mobile-container'>
                    <ResponsiveImage
                        className='check-mobile'
                        sources={[{ src: NoMobileWebp }]}
                        fallbackSrc={NoMobilePng}
                        alt={intl.formatMessage({ defaultMessage: 'Unavailable on mobile' })}
                    />
                    {<FormattedMessage defaultMessage="Unavailable on mobile" />}
                </div>
                : children
            : minWidth !== undefined ?
                minWidth < width ?
                    <div className='no-mobile-container'>
                        <ResponsiveImage
                            className='check-mobile'
                            sources={[{ src: NoMobileWebp }]}
                            fallbackSrc={NoMobilePng}
                            alt={intl.formatMessage({ defaultMessage: "Unavailable on mobile" })}
                        />
                        {<FormattedMessage defaultMessage="Unavailable on mobile" />}
                    </div>
                    : children
                : children
    );
};

export default CheckMobile;
