import { FormattedMessage } from "react-intl";
import Card from "../../../../../common/Card/Card";
import FAIcon from "../../../../../common/FAIcon/FAIcon";

const Details = () => {

    return (
        <Card icon={<FAIcon prefix='fad' name='circle-info' />} title={<FormattedMessage defaultMessage={'Details'} />}>
            <FormattedMessage defaultMessage={'Sunkhronos Details'} />
        </Card>
    );
};

export default Details;