import { Popover } from "antd";
import CircleButton from "../CircleButton/CircleButton";
import FAIcon from "../FAIcon/FAIcon";

interface Props {
  content: React.JSX.Element;
  title?: string;
  placement?: "top" | "left" | "right" | "bottom";
  prefix?: string,
  nameicone?: string
  beat?: boolean
  type?: string
  danger?: boolean
};

const PopoverButton = (props: Props) => {
  return (
    <Popover content={props.content} title={props.title || ''} placement={props.placement || 'top'}>
      <CircleButton
        danger={props.danger ? true : false}
        icon={<FAIcon prefix={props.prefix || 'fad'} name={props.nameicone || 'question'} beat={props.beat ? true : false} />}
      />
    </Popover>
  );
};

export default PopoverButton;
