import { Provider as StoreProvider } from 'react-redux';
import store from './store/store';
import RoutesProvider from './components/providers/RoutesProvider';
import ConfigProvider from './components/providers/ConfigProvider';
import './styles/general.css';
import './styles/variables.css';
import './styles/antd-override.css';
import FontAwesome from './components/common/FAIcon/fontawesome';
import { BrowserRouter } from 'react-router-dom';
import { webevolutionsPack } from './components/common/FAIcon/webevolutionsPack';
import { useEffect } from 'react';
import loadTheme from '../colors';
import RefreshTokenHandler from './handlers/RefreshTokenHandler';
import LanguageProvider from './components/providers/LanguageProvider';

FontAwesome.RegisterIconPack(webevolutionsPack);


const App = () => {
  useEffect(() => {
    loadTheme();
  }, []);

  return (
    <StoreProvider store={store}>
      <RefreshTokenHandler />
      <BrowserRouter>
        <ConfigProvider>
          <LanguageProvider>
            <RoutesProvider />
          </LanguageProvider>
        </ConfigProvider>
      </BrowserRouter>
    </StoreProvider>
  );
};

export default App;