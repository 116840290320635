import { CSSProperties, MouseEvent, ReactNode } from "react";

interface Props {
    className?: string;
    children: ReactNode;
    direction?: 'vertical' | 'horizontal';
    align?: 'flex-start' | 'center' | 'flex-end';
    space?: number;
    style?: CSSProperties;
    onClick?: (e: MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SpaceContent = (props: Props) => {
    return (
        <div
            className={props.className}
            style={{
                display: 'flex',
                flexDirection: props.direction == 'vertical' ? 'column' : 'row',
                justifyItems: 'center',
                justifyContent: props.align ?? undefined,
                gap: props.space !== undefined ? `${props.space}rem` : '0.5rem',
                ...props.style
            }}
        >
            {props.children}
        </div>
    );
};

export default SpaceContent;