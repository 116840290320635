import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import { TabSidebarType } from "../components/common/ContainerTabs/types";
import { updateSidebarType } from "../store/features/windowSlice";

const useWindow = () => {
    const dispatch = useAppDispatch();
    const window = useAppSelector(state => state.window);

    const changeSidebarType = useCallback((type: TabSidebarType) => {
        dispatch(updateSidebarType(type));
    }, [dispatch]);

    return {
        sidebarType: window.sidebarType,
        updateSidebarType: changeSidebarType
    };
};

export default useWindow;