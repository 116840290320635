import * as Sentry from '@sentry/react';
import { ReactNode, useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";
import de from '../../compiled-lang/de.json';
import en from '../../compiled-lang/en.json';
import fr from '../../compiled-lang/fr.json';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

interface Props {
    children?: ReactNode;
}

const LanguageProvider = (props: Props) => {
    const location = useLocation();

    const language = useMemo(() => {
        const lang = location.pathname.split("/")[1];
        switch (lang) {
            case 'en':
                return { locale: 'en', messages: en };
            case 'de':
                return { locale: 'de', messages: de };
            case 'fr':
            default:
                return { locale: 'fr', messages: fr };
        }
    }, [location.pathname]);

    useEffect(() => {
        dayjs.locale(language.locale);
    }, [language.locale])

    return (
        <IntlProvider
            locale={language.locale}
            messages={language.messages}
            defaultLocale="en"
            formats={{
                date: {
                    fr: {
                        day: '2-digit',
                        month: 'long',
                        year: '2-digit',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                    }
                }
            }}
            onError={(e) => {
                console.log("KROK", e);
                // FIXME: error process undifined
                // Sentry.captureException(e);
                // Sentry.captureMessage(`CODE:${e.code} | VERSION: ${process.env.REACT_APP_VERSION} | LANG: ${language.locale}`);
            }}
        >
            {props.children}
        </IntlProvider>
    )
}

export default LanguageProvider;