// Duotone
import { faChartLine as fasChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine';
import { faGears as fadGears } from '@fortawesome/pro-duotone-svg-icons/faGears';
import { faBars as fadBars } from '@fortawesome/pro-duotone-svg-icons/faBars';
import { faCalendar as fadCalendar } from '@fortawesome/pro-duotone-svg-icons/faCalendar';
import { faCircleInfo as fadCircleInfo } from '@fortawesome/pro-duotone-svg-icons/faCircleInfo';
import { faIndustryWindows as fadIndustryWindows } from '@fortawesome/pro-duotone-svg-icons/faIndustryWindows';
import { faArrowLeft as fadArrowLeft } from '@fortawesome/pro-duotone-svg-icons/faArrowLeft';
import { faRightFromBracket as fadRightFromBracket } from '@fortawesome/pro-duotone-svg-icons/faRightFromBracket';
import { faUser as fadUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faHouse as fadHouse } from '@fortawesome/pro-duotone-svg-icons/faHouse';
import { faNewspaper as fadNewspaper } from '@fortawesome/pro-duotone-svg-icons/faNewspaper';
import { faGrid2Plus as fadGrid2Plus } from '@fortawesome/pro-duotone-svg-icons/faGrid2Plus';
import { faFileInvoice as fadFileInvoice } from '@fortawesome/pro-duotone-svg-icons/faFileInvoice';
import { faGear as fadGear } from '@fortawesome/pro-duotone-svg-icons/faGear';
import { faChartLine as fadChartLine } from '@fortawesome/pro-duotone-svg-icons/faChartLine';
import { faUserTieHair as fadUserTieHair } from '@fortawesome/pro-duotone-svg-icons/faUserTieHair';
import { faRotate as fadRotate } from '@fortawesome/pro-duotone-svg-icons/faRotate';
import { faTable as fadTable } from '@fortawesome/pro-duotone-svg-icons/faTable';
import { faTimer as fadTimer } from '@fortawesome/pro-duotone-svg-icons/faTimer';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faInfo as fadInfo } from '@fortawesome/pro-duotone-svg-icons/faInfo';
import { faFilters as fadFilters } from '@fortawesome/pro-duotone-svg-icons/faFilters';
import { faFlaskGear as fadFlaskGear } from '@fortawesome/pro-duotone-svg-icons/faFlaskGear';
import { faEye as fadEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { faLightEmergencyOn as fadLightEmergencyOn } from '@fortawesome/pro-duotone-svg-icons/faLightEmergencyOn';
import { faBuildingMagnifyingGlass as fadBuildingMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons/faBuildingMagnifyingGlass';
import { faPencil as fadPencil } from '@fortawesome/pro-duotone-svg-icons/faPencil';
import { faRepeat as fadRepeat } from '@fortawesome/pro-duotone-svg-icons/faRepeat';
import { faFileCertificate as fadFileCertificate } from '@fortawesome/pro-duotone-svg-icons/faFileCertificate';
import { faFileContract as fadFileContract } from '@fortawesome/pro-duotone-svg-icons/faFileContract';
import { faPenField as fadPenField } from '@fortawesome/pro-duotone-svg-icons/faPenField';
import { faListTimeline as fadListTimeline } from '@fortawesome/pro-duotone-svg-icons/faListTimeline';
import { faCloudArrowDown as fadCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons/faCloudArrowDown';
import { faArrowDownToLine as fadArrowDownToLine } from '@fortawesome/pro-duotone-svg-icons/faArrowDownToLine';
import { faQuestion as fadQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion';
import { faTriangleExclamation as fadTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons/faTriangleExclamation';
import FA from './fontawesome';
import { IconPacks } from './IconPacks';


/**
 * Pack used for the sunkhronos library
 */
export const webevolutionsPack = FA.createIconPack(
  IconPacks.Webevolutions,
  // #region Duoton
  fadCircleInfo,
  fadIndustryWindows,
  fadArrowLeft,
  fadRightFromBracket,
  fadUser,
  fadHouse,
  fadNewspaper,
  fadGrid2Plus,
  fadFileInvoice,
  fadGear,
  fadChartLine,
  fadUserTieHair,
  fadRotate,
  fadTable,
  fadCalendar,
  fadTimer,
  fadBars,
  fadSpinnerThird,
  fadInfo,
  fadFilters,
  fadFlaskGear,
  fadEye,
  fadBuildingMagnifyingGlass,
  fadGears,
  fadLightEmergencyOn,
  fadPencil,
  fadRepeat,
  fadFileCertificate,
  fadFileContract,
  fadPenField,
  fadListTimeline,
  fadCloudArrowDown,
  fadArrowDownToLine,
  fadQuestion,
  fadTriangleExclamation,
  // #endregion

  // #region Regular
  farPlus,
  // #endregion

  // #region Solid
  fasChartLine,
  // #endregion
);