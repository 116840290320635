import { useWindowSize } from 'react-use';

const useDevice = () => {
    const { width } = useWindowSize();

    return {
        isSmartphone: width <= 900,
        isTablet: width <= 1145
    };
};

export default useDevice;