import { Divider } from "antd";
import { ReactNode } from "react";

interface Props {
    content: ReactNode;
    title?: ReactNode;
    icon?: ReactNode;
    extra?: ReactNode;
}

const SidebarContent = (props: Props) => {
    return (
        <>
            {
                props.title ?
                    <>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '5px', marginBottom: '10px' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                {props.icon ?? null}
                                <div className='title' style={{ margin: '0' }}>{props.title}</div>
                            </div>
                            {props.extra ?? null}
                        </div>
                        <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                    </>
                    :
                    <></>
            }
            {props.content}
        </>
    );
};

export default SidebarContent;