import API from './API';
import { DictionaryNumber } from './types/generalTypes';
import { NetworkResponse, NetworkUser, NetworkVersion } from './types/networkTypes';
import { NetworkSunkhronosCompany, NetworkSunkhronosCompanyUsage } from './types/applications/sunkhronosTypes';
import { NetworkClient, NetworkClientDetails } from './types/clientTypes';
import { NetworkInvoice } from './types/applications/invoiceTypes';
// const Compress = require('compress.js').default;

/**
 * Static class with Network API call methods
 */
export default class Network {
    static getAppVersion = (): Promise<NetworkResponse<NetworkVersion>> => API.get(`utils/app/version?app_name=${import.meta.env.VITE_APP_NAME}`);

    static login = (username: string, password: string) => API.login(username, password);
    static logout = () => API.logout();
    static verifyAuth = (access: string) => API.post('auth/verify', { access });

    static fetchUser = (): Promise<NetworkResponse<NetworkUser>> => API.get('user/current');

    static fetchSunkhronosCompanies = (date: string): Promise<NetworkResponse<NetworkSunkhronosCompany[]>> => API.get(`integrations/sunkhronos/company/all?date=${date}`);
    static fetchSunkhronosCompanyDetails = (id: number): Promise<NetworkResponse<NetworkSunkhronosCompany>> => API.get(`integrations/sunkhronos/company/details?id=${id}`);
    static fetchSunkhronosCompanyUsage = (id: number, startDate: string, endDate: string): Promise<NetworkResponse<DictionaryNumber<NetworkSunkhronosCompanyUsage>>> => API.get(`integrations/sunkhronos/company/usage?id=${id}&startDate=${startDate}&endDate=${endDate}`);

    static fetchClients = (): Promise<NetworkResponse<NetworkClient[]>> => API.get(`client/all`);
    static fetchClientDetails = (id: number): Promise<NetworkResponse<NetworkClientDetails>> => API.get(`client/details?id=${id}`);

    static fetchInvoices = (id: number): Promise<NetworkResponse<NetworkInvoice[]>> => API.get(`invoice/company?id=${id}`);

    static updateSunkhronosCompany = (company: NetworkSunkhronosCompany, date: string): Promise<NetworkResponse<NetworkSunkhronosCompany>> => API.post(`integrations/sunkhronos/company?id=${company.id}&date=${date}`, company);

    static renewSunkhronosLicence = (id: number): Promise<NetworkResponse<NetworkSunkhronosCompany>> => API.post(`integrations/sunkhronos/licence/renew?id=${id}`);
}
