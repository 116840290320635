import { useParams, useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import Billing from "./tabs/Billing/Billing";
import Configuration from "./tabs/Configuration/Configuration";
import { DictionaryString } from "../../../../../../../utils/types/generalTypes";
import { SunkhronosCompanyUsage } from "../../../../../../../utils/types/applications/sunkhronosTypes";
import { useContainerTabs } from "../../../../../../../contexts/containerTabsContext";
import { ConvertNetworkSunkhronosCompanyUsage } from "../../../../../../../utils/converters";
import Network from "../../../../../../../utils/Network";
import { showNotification } from "../../../../../../../utils/utils";
import { TabItem } from "../../../../../../common/ContainerTabs/types";
import FAIcon from "../../../../../../common/FAIcon/FAIcon";
import Usage from "./tabs/Usage/Usage";
import ContainerTabs from "../../../../../../common/ContainerTabs/ContainerTabs";
import { FormattedMessage, useIntl } from "react-intl";

const TABS = {
    BILLING: 'billing',
    CONFIGURATION: 'configuration',
    USAGE: 'usage'
};

const Company = () => {
    const intl = useIntl();
    const location = useLocation();
    const data = location.state;
    const { tab, id } = useParams();
    const { tabExtra, tabSidebars, addOrUpdateExtra, addOrUpdateSidebars } = useContainerTabs();

    // const [company, setCompany] = useState<SunkhronosCompany | undefined>(undefined);
    const [usage, setUsage] = useState<DictionaryString<SunkhronosCompanyUsage>>({});
    // const [companyLoading, setCompanyLoading] = useState(false);
    const [usageLoading, setUsageLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('year'));
    const [endDate, setEndDate] = useState(dayjs().endOf('year'));

    const handleRefreshCompanyUsage = useCallback(() => {
        if (id !== undefined) {
            setUsageLoading(true);
            Network.fetchSunkhronosCompanyUsage(Number.parseInt(id), startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then(
                (res) => {
                    setUsage(ConvertNetworkSunkhronosCompanyUsage(res.data));
                    setUsageLoading(false);
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the company usage' }), 'error');
                    setUsageLoading(false);
                }
            );
        }
    }, [endDate, id, intl, startDate]);

    // useEffect(() => {
    //     if (id !== undefined) {
    //         setCompanyLoading(true);
    //         Network.fetchSunkhronosCompanyDetails(Number.parseInt(id)).then(
    //             (res) => {
    //                 setCompany(ConvertNetworkSunkhronosCompany(res.data));
    //                 setCompanyLoading(false);
    //             },
    //             () => {
    //                 showNotification('An error occurred while retrieving the company details', 'error');
    //                 setCompanyLoading(false);
    //             }
    //         );
    //     }
    // }, [id]);

    useEffect(() => {
        handleRefreshCompanyUsage();
    }, [endDate, id, handleRefreshCompanyUsage, startDate]);

    const tabItems = useMemo((): TabItem[] => {
        return [
            {
                key: TABS.USAGE,
                label: <span><FAIcon prefix='fad' name='chart-line' />{<FormattedMessage
                    defaultMessage={'Usage'}
                />}</span>,
                children: <Usage
                    usage={usage}
                    loading={usageLoading}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    refreshUsage={handleRefreshCompanyUsage}
                    addOrUpdateExtra={addOrUpdateExtra}
                    addOrUpdateSidebars={addOrUpdateSidebars}
                    keyLink={TABS.USAGE}
                />
            },
            {
                key: TABS.BILLING,
                label: <span><FAIcon prefix='fad' name='file-invoice' />{<FormattedMessage
                    defaultMessage={'Billing'}
                />}</span>,
                disabled: true,
                children: <Billing />
            },
            {
                key: TABS.CONFIGURATION,
                label: <span><FAIcon prefix='fad' name='gear' />{<FormattedMessage
                    defaultMessage={'Configuration'}
                />}</span>,
                disabled: true,
                children: <Configuration />
            },
        ];
    }, [addOrUpdateExtra, addOrUpdateSidebars, endDate, handleRefreshCompanyUsage, startDate, usage, usageLoading]);

    return (
        <div >
            <ContainerTabs
                baseUrl={`applications/sunkhronos/companies/${id}`}
                backUrl='applications/sunkhronos/companies'
                tabItems={tabItems}
                tabExtra={tabExtra.find(item => item.key === tab)?.content}
                tabSidebars={tabSidebars.find(item => item.key === tab)?.content}
                place={`${data}`}
            />
        </div>

    );
};

export default Company;