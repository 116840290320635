import * as Sentry from "@sentry/react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import Network from "../../utils/Network";
import Applications from "../pages/Applications/Applications";
import Clients from "../pages/Clients/Clients";
import Sunkhronos from "../pages/Applications/Sunkhronos/Sunkhronos";
import SunkhronosCompany from '../pages/Applications/Sunkhronos/tabs/Companies/Company/Company';
import Layout from "../common/Layout/Layout";
import ClientDetails from "../pages/Clients/Client/Client";

const RoutesProvider = () => {
    const location = useLocation();
    const lang = location.pathname.split('/')[1] ?? "fr";
    const navigate = useNavigate();

    useEffect(() => {
        const SUPPORTED_LANGUAGES = ["en", "fr", "de"];
        const accessToken = localStorage.getItem("access");

        if (!lang || !SUPPORTED_LANGUAGES.includes(lang)) {
            navigate(`/fr${location.pathname.replace(/^\/[^/]+/, "")}`, { replace: true });
            return;
        }

        const handleNavigation = (isAuthenticated: boolean) => {
            const isOnLoginPage = location.pathname.includes("/login");
            if (isAuthenticated) {
                if (isOnLoginPage) navigate(`/${lang}/applications/sunkhronos/companies`);
            } else {
                if (!isOnLoginPage) navigate(`/${lang}/login`);
            }
        };

        if (accessToken) {
            Network.verifyAuth(accessToken)
                .then(() => handleNavigation(true))
                .catch(() => handleNavigation(false));
        }
    }, [lang, location.pathname, navigate]);

    return (
        <div id="anchor">
            <Sentry.ErrorBoundary>
                <Routes>
                    <Route path="/" element={<Navigate to="/fr" replace />} />

                    <Route path="/:lang">
                        <Route index element={<Navigate to={`/${lang}/login`} replace />} />
                        <Route path="login" Component={Login} />
                        <Route path="" Component={Layout}>
                            <Route path="dashboard" Component={Dashboard} />
                            <Route path="clients">
                                <Route index Component={Clients} />
                                <Route path=":id/:tab" Component={ClientDetails} />
                            </Route>
                            <Route path="applications">
                                <Route index Component={Applications} />
                                <Route path="sunkhronos">
                                    <Route index path=":tab?" Component={Sunkhronos} />
                                    <Route path="companies/:id/:tab" Component={SunkhronosCompany} />
                                </Route>
                            </Route>
                            <Route path="*" element={<Navigate to={`/${lang}/applications/sunkhronos/companies`} replace />} />
                        </Route>
                    </Route>
                </Routes>

            </Sentry.ErrorBoundary>
        </div>
    );

};

export default RoutesProvider;
