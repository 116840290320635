import { useCallback, useEffect, useMemo, useState } from "react";
import FAIcon from "../../../../../common/FAIcon/FAIcon";
import Network from "../../../../../../utils/Network";
import { ConvertDateToNetworkDate, ConvertNetworkSunkhronosCompanies } from "../../../../../../utils/converters";
import { getCompanyTypeLabel, getCompanyVersionLabel, showNotification } from "../../../../../../utils/utils";
import VirtualTable from "../../../../../common/VirtualTable/VirtualTable";
import { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { SunkhronosCompany } from "../../../../../../utils/types/applications/sunkhronosTypes";
import CircleButton from "../../../../../common/CircleButton/CircleButton";
import { Row } from "antd";
import { SunkhronosCompanyTypes, SunkhronosCompanyVersions } from "../../utils/constants";
import { ContainerTabsItemProps } from "../../../../../common/ContainerTabs/ContainerTabsItem";
import SpaceContent from "../../../../../common/SpaceContent/SpaceContent";
import { TabSidebar, TabSidebarType } from "../../../../../common/ContainerTabs/types";
import FloatingInput from "../../../../../common/Inputs/Floating/FloatingInput";
import FloatingDatePickerInput from "../../../../../common/Inputs/Floating/FloatingDatePicker";
import { Col, Popconfirm } from "antd/lib";
import FloatingSelectInput from "../../../../../common/Inputs/Floating/FloatingSelect";
import { FloatingSelectOption, FloatingSelectTypes } from "../../../../../common/Inputs/Floating/types";
import { cloneDeep } from "lodash";
import './styles.css';
import Fieldset from "../../../../../common/Fieldset/Fieldset";
import EditCompanyModal from "./modals/editCompanyModal";
import PopoverButton from "../../../../../common/Popover/PopoverButton";
import { FormattedMessage, useIntl } from "react-intl";
import getFormat from "../../utils/lang";

type Props = ContainerTabsItemProps;

const Companies = (props: Props) => {
    const intl = useIntl();

    const availableColumns = useMemo(() => [
        {
            title: 'Expiration',
            value: 'expiration',
            key: 'expiration',
        },
        {
            title: 'Licence',
            value: 'licence',
            key: 'licence',
            children: [
                {
                    title: 'Licence From',
                    value: 'licence_from',
                    key: 'licence_from'
                },
                {
                    title: 'Licence To',
                    value: 'licence_to',
                    key: 'licence_to',
                },
            ]
        },
        {
            title: 'Last Invoice',
            value: 'last_invoice',
            key: 'last_invoice',
        },
        {
            title: 'Contract',
            value: 'contract',
            key: 'contract',
            children: [
                {
                    title: 'Contract From',
                    value: 'contract_from',
                    key: 'contract_from',
                },
                {
                    title: 'Contract To',
                    value: 'contract_to',
                    key: 'contract_to',
                },
                {
                    title: 'Contract termination',
                    value: 'termination',
                    key: 'termination',
                },
            ]
        },
        {
            title: 'Version',
            value: 'version',
            key: 'version',
        },
        {
            title: 'Comment',
            value: 'comment',
            key: 'comment',
        },
    ], []);

    const columnKeys = useMemo(() => ['expiration', 'licence', 'licence_from', 'licence_to', 'last_invoice', 'contract', 'contract_from', 'contract_to', 'termination', 'version', 'comment'], []);

    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState<SunkhronosCompany[]>([]);
    const [visibleColumns, setVisibleColumns] = useState(
        columnKeys.filter(key => !['expiration', 'version'].includes(key))
    );

    //* Filters
    const [companyFilter, setCompanyFilter] = useState('');
    const [companyTypeFilter, setCompanyTypeFilter] = useState<number[]>([]);
    const [companyVersionFilter, setCompanyVersionFilter] = useState<number[]>([SunkhronosCompanyVersions.NONE.value]);
    const [selectedDate, setSelectedDate] = useState(dayjs().startOf('month'));
    const [selectedCompany, setSelectedCompany] = useState<SunkhronosCompany | undefined>(undefined);

    const navigate = useNavigate();

    const handleFetchCompanies = useCallback(() => {
        setLoading(true);
        Network.fetchSunkhronosCompanies(ConvertDateToNetworkDate(selectedDate)).then(
            (res) => {
                setLoading(false);
                const tmpCompanies = ConvertNetworkSunkhronosCompanies(res.data);
                setCompanies(tmpCompanies);
                if (selectedCompany)
                    setSelectedCompany(cloneDeep(tmpCompanies.find(c => c.id === selectedCompany?.id)));
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the companies' }), 'error');
                setLoading(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    const handleRenewLicence = useCallback((id: number) => {
        setLoading(true);
        Network.renewSunkhronosLicence(id).then(
            (res) => {
                const tmpCompanies = cloneDeep(companies);
                const index = tmpCompanies.findIndex(c => c.id === res.data.id);
                if (index !== -1)
                    tmpCompanies[index] = {
                        ...tmpCompanies[index],
                        licenceFrom: res.data.licence_from ? dayjs(res.data.licence_from) : undefined,
                        licenceTo: res.data.licence_to ? dayjs(res.data.licence_to) : undefined,
                        lastInvoice: res.data.last_invoice ? dayjs(res.data.last_invoice) : undefined,
                    };

                setCompanies(tmpCompanies);
                setLoading(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: "An error occurred while updating the company's licence" }), 'error');
                setLoading(false);
            }
        );
    }, [companies, intl]);

    useEffect(() => {
        handleFetchCompanies();
    }, [handleFetchCompanies]);

    const haveActiveFilter = useMemo(() => {
        return (
            companyFilter.length > 0 ||
            companyTypeFilter.length > 0 ||
            companyVersionFilter.length > 0
        );
    }, [companyFilter.length, companyTypeFilter.length, companyVersionFilter.length]);

    const companyTypeOptions = useMemo((): FloatingSelectOption[] => SunkhronosCompanyTypes ? Object.values(SunkhronosCompanyTypes).map<FloatingSelectOption>(p => ({ label: p.label, value: p.value })) : [], []);
    const companyVersionOptions = useMemo((): FloatingSelectOption[] => SunkhronosCompanyVersions ? Object.values(SunkhronosCompanyVersions).map<FloatingSelectOption>(p => ({ label: p.label, value: p.value })) : [], []);

    const fieldsetFilter = useMemo(() => (
        <Fieldset legend={<><FAIcon prefix='fad' name='filters' /> Filters</>}>
            <Row gutter={[10, 30]} style={{ marginTop: '1rem' }}>
                <Col xs={{ span: 24 }}>
                    <FloatingDatePickerInput
                        label={intl.formatMessage({ defaultMessage: 'Usage date' })}
                        picker='month'
                        value={selectedDate}
                        allowClear={false}
                        disabled={loading}
                        onChange={(e) => setSelectedDate(e.startOf('month'))}
                    />
                </Col>
                <Col xs={{ span: 24 }}>
                    <FloatingInput
                        label={intl.formatMessage({ defaultMessage: 'Filter by company name' })}
                        value={companyFilter}
                        allowClear
                        onChange={(e) => setCompanyFilter(e.target.value)}
                    />
                </Col>
                <Col xs={{ span: 24 }}>
                    <FloatingSelectInput
                        label={intl.formatMessage({ defaultMessage: 'Filter by company type' })}
                        value={companyTypeFilter}
                        allowClear
                        options={companyTypeOptions}
                        mode='multiple'
                        onChange={(e: number[]) => setCompanyTypeFilter(e)}
                    />
                </Col>
                <Col xs={{ span: 24 }}>
                    <FloatingSelectInput
                        label={intl.formatMessage({ defaultMessage: 'Filter by company version' })}
                        value={companyVersionFilter}
                        allowClear
                        options={companyVersionOptions}
                        mode='multiple'
                        onChange={(e: number[]) => setCompanyVersionFilter(e)}
                    />
                </Col>
            </Row>
        </Fieldset>
    ), [companyFilter, companyTypeFilter, companyTypeOptions, companyVersionFilter, companyVersionOptions, intl, loading, selectedDate]);

    const fieldsetConfig = useMemo(() => (
        <Fieldset legend={<><FAIcon prefix='fad' name='gears' /> {"Config"}</>}>
            <Row gutter={[10, 30]} style={{ marginTop: '1rem' }}>
                <Col xs={{ span: 24 }}>
                    <FloatingSelectInput
                        type={FloatingSelectTypes.TREE}
                        treeData={availableColumns}
                        value={visibleColumns}
                        label={intl.formatMessage({ defaultMessage: "Selected Columns" })}
                        maxTagCount='responsive'
                        multiple
                        treeCheckable
                        onChange={(e: string[]) => setVisibleColumns(e)}
                    />
                </Col>
            </Row>
        </Fieldset>
    ), [availableColumns, intl, visibleColumns]);

    const getSidebar = useMemo((): TabSidebar[] => {
        return [
            {
                button: {
                    icon: <FAIcon prefix='fad' name='flask-gear' />,
                    title: 'Filters'
                },
                hasBadge: haveActiveFilter,
                content:
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} style={{ height: 'auto' }}>
                            {fieldsetFilter}
                        </Col>
                        <Col xs={{ span: 24 }}>
                            {fieldsetConfig}
                        </Col>
                    </Row>,
                type: TabSidebarType.SETTINGS
            }
        ];
    }, [haveActiveFilter, fieldsetFilter, fieldsetConfig]);

    useEffect(() => {
        props.addOrUpdateExtra(getExtra, props.keyLink);
        props.addOrUpdateSidebars(getSidebar, props.keyLink);
    });

    const filteredCompanies = useMemo(() => {
        let tmpCompanies = cloneDeep(companies);

        if (companyFilter.length > 0)
            tmpCompanies = tmpCompanies.filter(c => c.name.toLocaleLowerCase().includes(companyFilter.toLocaleLowerCase()));

        if (companyTypeFilter.length > 0)
            tmpCompanies = tmpCompanies.filter(c => c.type !== undefined ? companyTypeFilter.includes(c.type) : false);

        if (companyVersionFilter.length > 0)
            tmpCompanies = tmpCompanies.filter(c => c.version !== undefined ? companyVersionFilter.includes(c.version) : false);

        return tmpCompanies;
    }, [companies, companyFilter, companyTypeFilter, companyVersionFilter]);

    const calculateTermination = useCallback((date: Dayjs, delay: number = 3) => {
        return date.add(-delay, 'month');
    }, []);

    const licenceWarningDate = useMemo(() => dayjs().add(1, 'month'), []);
    const licenceErrorDate = useMemo(() => dayjs().add(1, 'month').add(-1, "day"), []);
    const contractWarningDate = useMemo(() => dayjs().add(4, 'month'), []);
    const contractErrorDate = useMemo(() => dayjs().add(3, 'month'), []);
    const terminationDelay = useMemo(() => { return 3; }, []);
    const contractResilationWarningDate = useMemo(() => dayjs().add(terminationDelay + 2, 'month'), [terminationDelay]);
    const contractResilationErrorDate = useMemo(() => dayjs().add(terminationDelay, 'month'), [terminationDelay]);

    const columns = useMemo((): ColumnsType<SunkhronosCompany> => [
        {
            key: 'name',
            dataIndex: 'name',
            title: intl.formatMessage({ defaultMessage: 'Company name' }),
            className: 'w-300',
            fixed: 'left',
            hidden: false,
            onFilter: (value, record) => {
                return record.name
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            sorter: (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
        },
        {
            key: 'expiration',
            dataIndex: 'expiration',
            title: intl.formatMessage({ defaultMessage: 'Expiration' }),
            align: 'center',
            className: 'w-120',
            hidden: !visibleColumns.includes('expiration'),
            render: (_, record) => record.expiration ? record.expiration.format(getFormat('DATE')) : '-',
            sorter: (a, b) => {
                if (!a.expiration && !b.expiration) return 0; // Both are undefined or falsy
                if (!a.expiration) return 1; // Treat undefined as greater than a defined date
                if (!b.expiration) return -1; // Treat undefined as less than a defined date
                return dayjs(a.expiration).isBefore(dayjs(b.expiration)) ? -1 : 1; // Compare valid dates
            },
            defaultSortOrder: 'descend'
        },

        //TODO: INVOICE MODULE NOT READY
        {
            key: 'licence',
            title: intl.formatMessage({ defaultMessage: 'Licence' }),
            className: 'w-240 odd',
            hidden: !visibleColumns.includes('licence_from') && !visibleColumns.includes('licence_to'),
            children: [
                {
                    key: 'licence_from',
                    dataIndex: 'licence_from',
                    title: intl.formatMessage({ defaultMessage: 'From' }),
                    align: 'center',
                    className: 'w-120 odd',
                    hidden: !visibleColumns.includes('licence_from'),
                    render: (_, record) => record.licenceFrom ? record.licenceFrom.format(getFormat('DATE')) : '-',
                    sorter: (a, b) => {
                        if (!a.licenceFrom && !b.licenceFrom) return 0; // Both are undefined or falsy
                        if (!a.licenceFrom) return 1; // Treat undefined as greater than a defined date
                        if (!b.licenceFrom) return -1; // Treat undefined as less than a defined date
                        return dayjs(a.licenceFrom).isBefore(dayjs(b.licenceFrom)) ? -1 : 1; // Compare valid dates
                    },
                },
                {
                    key: 'licence_to',
                    dataIndex: 'licence_to',
                    title: intl.formatMessage({ defaultMessage: 'To' }),
                    align: 'center',
                    className: 'w-120 odd',
                    hidden: !visibleColumns.includes('licence_to'),
                    render: (_, record) => (
                        <span className={`licence${record.licenceTo ? record.licenceTo.isBefore(licenceErrorDate, 'date') ? ' error' : record.licenceTo.isBefore(licenceWarningDate, 'date') ? ' warn' : '' : ''}`}>
                            {record.licenceTo?.format(getFormat('DATE')) || '-'}
                        </span>
                    ),
                    sorter: (a, b) => {
                        if (!a.licenceTo && !b.licenceTo) return 0; // Both are undefined or falsy
                        if (!a.licenceTo) return 1; // Treat undefined as greater than a defined date
                        if (!b.licenceTo) return -1; // Treat undefined as less than a defined date
                        return dayjs(a.licenceTo).isBefore(dayjs(b.licenceTo)) ? -1 : 1; // Compare valid dates
                    },
                },
            ]
        },
        {
            key: 'last_invoice',
            dataIndex: 'last_invoice',
            title: intl.formatMessage({ defaultMessage: 'Last invoice' }),
            align: 'center',
            hidden: !visibleColumns.includes('last_invoice'),
            className: 'w-150 even',
            render: (_, record) => record.lastInvoice ? record.lastInvoice.format(getFormat('DATE')) : '-',
            sorter: (a, b) => {
                if (!a.lastInvoice && !b.lastInvoice) return 0; // Both are undefined or falsy
                if (!a.lastInvoice) return 1; // Treat undefined as greater than a defined date
                if (!b.lastInvoice) return -1; // Treat undefined as less than a defined date
                return dayjs(a.lastInvoice).isBefore(dayjs(b.lastInvoice)) ? -1 : 1; // Compare valid dates
            },
        },
        {
            key: 'contract',
            title: intl.formatMessage({ defaultMessage: 'Contract' }),
            className: 'w-360 odd',
            hidden: !visibleColumns.includes('contract_to') && !visibleColumns.includes('contract_from') && !visibleColumns.includes('termination'),
            children: [
                {
                    key: 'contract_from',
                    dataIndex: 'contract_from',
                    title: intl.formatMessage({ defaultMessage: 'From' }),
                    align: 'center',
                    className: 'w-120 odd',
                    hidden: !visibleColumns.includes('contract_from'),
                    render: (_, record) => record.contractFrom ? record.contractFrom.format(getFormat('DATE')) : '-',
                    sorter: (a, b) => {
                        if (!a.contractFrom && !b.contractFrom) return 0; // Both are undefined or falsy
                        if (!a.contractFrom) return 1; // Treat undefined as greater than a defined date
                        if (!b.contractFrom) return -1; // Treat undefined as less than a defined date
                        return dayjs(a.contractFrom).isBefore(dayjs(b.contractFrom)) ? -1 : 1; // Compare valid dates
                    },
                },
                {
                    key: 'contract_to',
                    dataIndex: 'contract_to',
                    title: intl.formatMessage({ defaultMessage: 'To' }),
                    align: 'center',
                    className: 'w-120 odd',
                    hidden: !visibleColumns.includes('contract_to'),
                    render: (_, record) => (
                        <span className={`contract${record.contractTo ? record.contractTo.isBefore(contractErrorDate, 'date') ? ' error' : record.contractTo.isBefore(contractWarningDate, 'date') ? ' warn' : '' : ''}`}>
                            {record.contractTo?.format(getFormat('DATE')) || '-'}
                        </span>
                    ),
                    sorter: (a, b) => {
                        if (!a.contractTo && !b.contractTo) return 0; // Both are undefined or falsy
                        if (!a.contractTo) return 1; // Treat undefined as greater than a defined date
                        if (!b.contractTo) return -1; // Treat undefined as less than a defined date
                        return dayjs(a.contractTo).isBefore(dayjs(b.contractTo)) ? -1 : 1; // Compare valid dates
                    },
                },
                {
                    key: 'termination',
                    dataIndex: 'termination',
                    title: intl.formatMessage({ defaultMessage: 'Termination' }),
                    align: 'center',
                    className: 'w-120 odd',
                    hidden: !visibleColumns.includes('termination'),
                    render: (_, record) => {
                        const termination = record.contractTo ? calculateTermination(record.contractTo, terminationDelay) : undefined;
                        return (
                            <span className={`contract${termination ? termination.isBefore(contractResilationErrorDate, 'date') ? ' error' : termination.isBefore(contractResilationWarningDate, 'date') ? ' warn' : '' : ''}`}>
                                {termination?.format(getFormat('DATE')) || '-'}
                            </span>
                        );
                    },
                    sorter: (a, b) => {
                        const terminationDelay = 3;
                        const aResilation = a.contractTo ? calculateTermination(a.contractTo, terminationDelay) : undefined;
                        const bResilation = b.contractTo ? calculateTermination(b.contractTo, terminationDelay) : undefined;

                        if (!bResilation && !aResilation) return 0; // Both are undefined or falsy
                        if (!aResilation) return 1; // Treat undefined as greater than a defined date
                        if (!bResilation) return -1; // Treat undefined as less than a defined date
                        return dayjs(aResilation).isBefore(dayjs(bResilation)) ? -1 : 1; // Compare valid dates
                    },
                },
            ]
        },
        //TODO: INVOICE MODULE NOT READY

        {
            key: 'events',
            dataIndex: 'events',
            title: intl.formatMessage({ defaultMessage: 'Events' }),
            align: 'center',
            className: 'w-130',
            render: (_, record) => record.events,
            sorter: (a, b) => {
                if (a.events === undefined && b.events === undefined) return 0; // Both are undefined or falsy
                if (a.events === undefined) return 1; // Treat undefined as greater than a defined date
                if (b.events === undefined) return -1; // Treat undefined as less than a defined date
                return a.events < b.events ? -1 : 1; // Compare valid dates
            },
        },
        {
            key: 'admins',
            dataIndex: 'admins',
            title: intl.formatMessage({ defaultMessage: 'Admins' }),
            align: 'center',
            className: 'w-130',
            render: (_, record) => record.admins,
            sorter: (a, b) => {
                if (a.admins === undefined && b.admins === undefined) return 0; // Both are undefined or falsy
                if (a.admins === undefined) return 1; // Treat undefined as greater than a defined date
                if (b.admins === undefined) return -1; // Treat undefined as less than a defined date
                return a.admins < b.admins ? -1 : 1; // Compare valid dates
            },
        },
        {
            key: 'group-admins',
            dataIndex: 'group-admins',
            title: intl.formatMessage({ defaultMessage: 'Group admins' }),
            align: 'center',
            className: 'w-180',
            render: (_, record) => record.groupAdmins,
            sorter: (a, b) => {
                if (a.groupAdmins === undefined && b.groupAdmins === undefined) return 0; // Both are undefined or falsy
                if (a.groupAdmins === undefined) return 1; // Treat undefined as greater than a defined date
                if (b.groupAdmins === undefined) return -1; // Treat undefined as less than a defined date
                return a.groupAdmins < b.groupAdmins ? -1 : 1; // Compare valid dates
            },
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: intl.formatMessage({ defaultMessage: 'Type' }),
            className: 'w-120',
            render: (_, record) => record.type !== undefined ? getCompanyTypeLabel(record.type) : 'Unknown',
            sorter: (a, b) => {
                if (a.type === undefined && b.type === undefined) return 0;
                if (a.type === undefined) return 1;
                if (b.type === undefined) return -1;
                return a.type - b.type;
            },
        },
        {
            key: 'version',
            dataIndex: 'version',
            title: intl.formatMessage({ defaultMessage: 'Version' }),
            className: 'w-150',
            render: (_, record) => getCompanyVersionLabel(record.version),
            sorter: (a, b) => {
                if (a.version == null && b.version == null) return 0;
                if (a.version == null) return 1;  // undefined is treated as larger, so it comes later
                if (b.version == null) return -1; // undefined is treated as larger, so it comes later
                return a.version - b.version;
            }
        },
        {
            key: 'comment',
            dataIndex: 'comment',
            title: intl.formatMessage({ defaultMessage: 'Comment' }),
            className: 'w-250',
            hidden: !visibleColumns.includes('comment'),
            render: (_, record) => record.comment,
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: '',
            className: 'w-100',
            fixed: 'right',
            render: (_, record) => (
                <SpaceContent align='center'>
                    <Popconfirm
                        title={intl.formatMessage({ defaultMessage: ' Renewing licence' })}
                        description={
                            <>
                                <p>{intl.formatMessage({ defaultMessage: "This action will renew this company's licence" })}</p>
                                <p>{intl.formatMessage({ defaultMessage: "Do you want to continue?" })}</p>
                            </>
                        }
                        icon={<FAIcon prefix='fad' name='light-emergency-on' color='red' />}
                        onConfirm={(e) => {
                            if (e) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            handleRenewLicence(record.id);
                        }}
                        placement='left'
                        onCancel={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                        }}
                    >
                        <CircleButton
                            icon={<FAIcon prefix='fad' name='repeat' />}
                            title={intl.formatMessage({ defaultMessage: 'Renew licence' })}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        />
                    </Popconfirm>
                    <CircleButton
                        icon={<FAIcon prefix='fad' name='pencil' />}
                        title={intl.formatMessage({ defaultMessage: 'Edit company' })}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCompany(cloneDeep(record));
                        }}
                    />
                </SpaceContent>
            ),
        },
    ], [calculateTermination, contractErrorDate, contractResilationErrorDate, contractResilationWarningDate, contractWarningDate, handleRenewLicence, intl, licenceErrorDate, licenceWarningDate, terminationDelay, visibleColumns]);

    const alerts = useMemo(() => {
        return filteredCompanies.map((record) => {
            const Errors: string[] = [];
            const Warnings: string[] = [];
            let isError = false;
            let isWarning = false;

            const today = dayjs().add(-1, 'day');


            // Vérification de la licence
            if (record.licenceTo) {
                if (record.licenceTo.isBefore(licenceErrorDate, 'date') && record.licenceTo.isAfter(today, 'date')) {
                    Errors.push(intl.formatMessage(
                        { defaultMessage: "The licence of the company {company} expires in less than 1 month" },
                        { company: record.name }
                    ));
                    isError = true;
                } else if (record.licenceTo.isBefore(licenceWarningDate, 'date') && record.licenceTo.isAfter(today, 'date')) {
                    Warnings.push((intl.formatMessage(
                        { defaultMessage: "The licence of the company {company} expires in 1 month" },
                        { company: record.name }
                    )));
                    isWarning = true;
                }
            }

            // Vérification du contrat
            if (record.contractTo) {
                if (record.contractTo.isBefore(contractErrorDate, 'date') && record.contractTo.isAfter(today, 'date')) {
                    Errors.push((intl.formatMessage(
                        { defaultMessage: "The contract of the company {company} expires in less than 3 months" },
                        { company: record.name }
                    )));
                    isError = true;
                } else if (record.contractTo.isBefore(contractWarningDate, 'date') && record.contractTo.isAfter(today, 'date')) {
                    Warnings.push((intl.formatMessage(
                        { defaultMessage: "The contract of the company {company} expires in less than 4 months" },
                        { company: record.name }
                    )));
                    isWarning = true;
                }
            }

            return {
                ...record,
                Errors: Errors,
                Warnings: Warnings,
                isError,
                isWarning,
            };
        });
    }, [filteredCompanies, licenceErrorDate, licenceWarningDate, intl, contractErrorDate, contractWarningDate]);

    const getExtra = useMemo(() => {

        // Vérifie si des erreurs ou avertissements existent dans les alertes
        const hasErrors = alerts.some(rec => rec.Errors.length > 0);
        const hasWarnings = alerts.some(rec => rec.Warnings.length > 0);

        const beat = hasErrors || hasWarnings ? true : false;
        const danger = beat;

        return (
            <SpaceContent>
                <PopoverButton
                    content={
                        <div>
                            {/* Affichage des erreurs */}
                            {hasErrors && (
                                <>
                                    <span><FormattedMessage defaultMessage="Errors :" /></span>
                                    <ul>
                                        {alerts.map((rec, recIndex) =>
                                            rec.Errors.length > 0 && (
                                                <li key={`licence-error-${recIndex}`}>
                                                    {rec.Errors.map((error, index) => (
                                                        <span key={`licence-error-${recIndex}-${index}`}>{error}</span>
                                                    ))}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </>
                            )}

                            {/* Affichage des avertissements */}
                            {hasWarnings && (
                                <>
                                    <span><FormattedMessage defaultMessage="Warnings :" /></span>
                                    <ul>
                                        {alerts.map((rec, recIndex) =>
                                            rec.Warnings.length > 0 && (
                                                <li key={`contract-warning-${recIndex}`}>
                                                    {rec.Warnings.map((warn, index) => (
                                                        <span key={`contract-warning-${recIndex}-${index}`}>{warn}</span>
                                                    ))}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </>
                            )}

                            {/* Si aucune erreur ou warning */}
                            {!hasErrors && !hasWarnings && <p><FormattedMessage defaultMessage={'No Errors'} /></p>}
                        </div>
                    }
                    prefix="fad"
                    nameicone="triangle-exclamation"
                    beat={beat}
                    danger={danger}
                />
                <PopoverButton
                    title={intl.formatMessage({ defaultMessage: "Help" })}
                    content={
                        <div>
                            <><FormattedMessage defaultMessage="License:" /></>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="License in 1 month (exactly) → The entire line turns {color}."
                                        values={{ color: <span style={{ color: 'var(--yellow)' }}>{<FormattedMessage defaultMessage="yellow" />}</span> }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="License in less than a month → The entire line turns {color}."
                                        values={{ color: <span style={{ color: 'var(--red)' }}>{<FormattedMessage defaultMessage="red" />}</span> }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="Expired license → Only the text is {color}."
                                        values={{ color: <span style={{ color: 'var(--red)' }}>{<FormattedMessage defaultMessage="red" />}</span> }}
                                    />
                                </li>
                                {/* <li>Licence dans 1 mois (jour pour jour) → La ligne entière devient <span style={{ 'color': 'var(--yellow)' }}>jaune.</span></li>
                                <li>Licence dans moins d’un mois → La ligne entière devient <span style={{ 'color': 'var(--red)' }}>rouge.</span></li>
                                <li>Licence expirée → Seul le texte est <span style={{ 'color': 'var(--red)' }}>rouge.</span></li> */}
                            </ul>
                            <><FormattedMessage defaultMessage="Contract:" /></>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="Contract in less than 4 months → The entire line turns {color}."
                                        values={{ color: <span style={{ color: 'var(--yellow)' }}>yellow</span> }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="Contract in less than 3 months → The entire line turns {color}."
                                        values={{ color: <span style={{ color: 'var(--red)' }}>{<FormattedMessage defaultMessage="red" />}</span> }}
                                    />
                                </li>
                                {/* <li>Contrat dans moins de 4 mois → La ligne entière devient <span style={{ 'color': 'var(--yellow)' }}>jaune.</span></li>
                                <li> Contrat dans moins de 3 mois → La ligne entière devient <span style={{ 'color': 'var(--red)' }}>rouge.</span></li> */}
                            </ul>
                            <><FormattedMessage id="section.termination" defaultMessage="Termination:" /></>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="Termination before 5 months → Only the relevant column turns {color}."
                                        values={{ color: <span style={{ color: 'var(--yellow)' }}>{<FormattedMessage defaultMessage="yellow" />}</span> }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        defaultMessage="Termination before 3 months → Only the relevant column turns {color}."
                                        values={{ color: <span style={{ color: 'var(--red)' }}>{<FormattedMessage defaultMessage="red" />}</span> }}
                                    />
                                </li>
                                {/* <li>Résiliation avant 5 mois → Seule la colonne concernée devient <span style={{ 'color': 'var(--yellow)' }}>jaune.</span></li>
                                <li>Résiliation avant 3 mois → Seule la colonne concernée devient <span style={{ 'color': 'var(--red)' }}>rouge.</span></li> */}
                            </ul>
                        </div>
                    }
                />
                <CircleButton
                    onClick={handleFetchCompanies}
                    icon={<FAIcon prefix='fad' name='rotate' />}
                    loading={loading}
                />
                <CircleButton
                    disabled
                    icon={<FAIcon prefix='far' name='plus' />}
                />
            </SpaceContent>
        );
    }, [alerts, handleFetchCompanies, intl, loading]);

    return (
        <div>
            <VirtualTable<SunkhronosCompany>
                loading={{
                    spinning: loading,
                    indicator: <FAIcon prefix='fad' name='spinner-third' style={{ width: 50, height: 50 }} spin />
                }}
                dataSource={filteredCompanies}
                columns={columns}
                pagination={false}
                height={310}
                rowKey={(record) => `sunk-company-${record.id}`}
                onRow={(record) => {
                    let className = 'company';

                    const analysedRecord = alerts.find((r) => r.id === record.id);
                    if (!analysedRecord) return { className };

                    if (analysedRecord.isError) {
                        className += ' error';
                    } else if (analysedRecord.isWarning) {
                        className += ' warn';
                    }

                    return {
                        onClick: () => {
                            navigate(`${record.id}/usage`, { state: record.name });
                        },
                        className,
                    };
                }}
            />
            <EditCompanyModal
                open={selectedCompany !== undefined}
                onCancel={(company) => {
                    if (company !== undefined) {
                        const tmpCompanies = cloneDeep(companies);
                        const index = tmpCompanies.findIndex(c => c.id === company.id);
                        if (index !== -1)
                            tmpCompanies[index] = company;

                        setCompanies(tmpCompanies);
                    }

                    setSelectedCompany(undefined);
                }}
                selectedCompany={selectedCompany}
                selectedDate={selectedDate}
            />
        </div>
    );
};

export default Companies;