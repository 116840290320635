import { Spin } from "antd";
import { ReactNode } from "react";
import FAIcon from "../FAIcon/FAIcon";

interface Props {
    children?: ReactNode;
    spinning?: boolean;
}

const Spinner = (props: Props) => {
    return (
        <Spin
            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            spinning={props.spinning}
            indicator={<FAIcon prefix='fad' name='spinner-third' style={{ width: 50, height: 50 }} spin />}
        >
            {props.children}
        </Spin>
    );
};

export default Spinner;