import { ReactNode } from 'react';
import './styles.css';

interface Props {
    className?: string;
    children: ReactNode;
}

const Title = (props: Props) => {
    return (
        <div className={`title ${props.className ? props.className : ''}`}>
            <span>{props.children}</span>
        </div>
    );
};

export default Title;