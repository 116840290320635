import React from 'react';
import './responsiveImage.css';
import { getMimeType } from '../../../utils/utils';

interface ImageSource {
    src: string;
    type?: MimeType; // MIME type (par exemple, 'image/webp', 'image/jpeg', etc.)
}

interface ResponsiveImageProps {
    sources: ImageSource[]; // Liste des sources (ordre de priorité)
    fallbackSrc: string;    // Source d'image pour le fallback
    alt: string;            // Texte alternatif
    className?: string;     // Classe CSS facultative
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ sources, fallbackSrc, alt, className }) => (
    <picture>
        {sources.map(({ src, type }, index) => (
            <source key={index} srcSet={src} type={type || getMimeType(src)} />
        ))}
        {/* Fallback image si aucune source n'est compatible */}
        <img src={fallbackSrc} alt={alt} className={`${className ?? ''} responsive-image`} />
    </picture>
);

export default ResponsiveImage;

export type MimeType =
    | 'image/webp'
    | 'image/avif'
    | 'image/jpeg'
    | 'image/png'
    | 'image/gif'
    | 'image/svg+xml';