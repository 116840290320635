import { ReactElement, ReactNode } from "react";

export interface TabItem {
    key: string;
    label: ReactNode;
    disabled?: boolean;
    hideMobile?: boolean;
    children: ReactElement;
}

export const enum TabSidebarType {
    NONE = 'None',
    FILTERS = 'Filters',
    SETTINGS = "Settings"
}

export interface TabSidebarButton {
    title: string;
    icon: ReactNode;
}

export interface TabSidebar {
    type: TabSidebarType;
    button: TabSidebarButton;
    titleBar?: {
        title?: ReactNode;
        icon?: ReactNode;
        extra?: ReactNode;
    };
    content: ReactNode;
    hasBadge?: boolean;
}

export const enum Direction {
    Vertical = "v",
    Horizontal = "h"
}

export interface ContainerTabsState {
    tabItems: TabItem[];
    tabListOfExtras?: { content: ReactNode, key: string; }[];
    tabListOfSidebars?: { content: TabSidebar[], key: string; }[];
}