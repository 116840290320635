import { createSlice } from "@reduxjs/toolkit";
import { WindowState } from "../../utils/types/redux/windowTypes";
import { TabSidebarType } from "../../components/common/ContainerTabs/types";

// #region Initial state
export const initialState: WindowState = {
    sidebarType: TabSidebarType.NONE
};

// #region Slice
const windowSlice = createSlice({
    name: 'window',
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
        updateSidebarType: (state, action) => {
            return {
                ...state,
                sidebarType: action.payload
            };
        }
    },
});

export const { reset, updateSidebarType } = windowSlice.actions;

export default windowSlice.reducer;