import { Input } from "antd";
import { InputProps } from "antd/lib";
import { ReactNode, useMemo, useState } from "react";
import './styles.css';
import { FormattedMessage } from "react-intl";

interface IProps {
    label: ReactNode;
    inputSize?: 'small' | 'normal';
    error?: boolean;
    errorText?: ReactNode;
    imported?: boolean;
    email?: boolean;
    loading?: boolean;
}

type Props = IProps & InputProps;

const FloatingInput = (props: Props) => {
    const [focus, setFocus] = useState<boolean>(false);

    const labelClass = useMemo(() => `floating-label${focus || props.value || props.value === 0 ? ' active' : ''}`, [props.value, focus]);

    return (
        <div className={`${props.className !== undefined ? props.className : ''} floating-label-input${props.inputSize === 'small' ? ' small' : ''}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} style={props.style}>
            <Input
                {...props}
                type={props.email ? 'email' : ''}
                className={`input ${props.error ? 'error' : ''}`}
            />

            <span className={'label'}>
                <span className={labelClass}>
                    {props.required && <span style={{ color: "var(--base-error)" }}>{'*'}</span>} {props.label}
                </span>
            </span>
            {
                props.error &&
                <span className={'error-text'}>
                    {props.errorText !== undefined ? props.errorText : <FormattedMessage defaultMessage='This field is required' />}
                </span>
            }
        </div>
    );
};

export default FloatingInput;