import { FloatButton } from "antd";
import './styles.css';
import FAIcon from "../FAIcon/FAIcon";
import { useUpdateContext } from "../../../contexts/updateContext";
import { FormattedMessage } from "react-intl";

const AppButton = () => {
    const { loading, refreshVersion } = useUpdateContext();

    return (
        <>
            <FloatButton
                className={`app-button-group${loading ? ' disabled' : ''}`}
                icon={loading ? <FAIcon prefix='fad' name='spinner-third' spin /> : <FAIcon prefix='fad' name='rotate' />}
                tooltip={<FormattedMessage defaultMessage="Check for an update" />}
                onClick={() => {
                    if (!loading)
                        refreshVersion();
                }}
            />
        </>
    );
};

export default AppButton;