import { useEffect } from "react";
import API from "../utils/API";
import { AxiosError, InternalAxiosRequestConfig } from "axios";

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
    _retry?: boolean;
}

const RefreshTokenHandler = () => {
    useEffect(() => {
        const refreshTokenInterceptor = API.interceptors.response.use(
            undefined,
            async (error: AxiosError) => {
                const originalRequest = error.config as CustomAxiosRequestConfig;
                const isAuthRequest = originalRequest?.url ? originalRequest?.url.includes('auth/login') || originalRequest.url.includes('auth/refresh') : false;

                if (!isAuthRequest && error.response?.status === 401 && originalRequest && !originalRequest._retry) {
                    originalRequest._retry = true;

                    try {
                        const promise = await API.refreshAuth();

                        const accessToken = promise.data.access;
                        if (accessToken) {
                            localStorage.setItem('access', accessToken);
                            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

                            if (originalRequest.url && originalRequest.url.includes('auth/verify')) {
                                originalRequest.data = { access: accessToken };
                            }

                            return API.axios(originalRequest);
                        }
                    }
                    catch (refreshError) {
                        console.error('Error refreshing access token:', refreshError);
                        localStorage.removeItem('access');
                        window.location.pathname = '/login';
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            API.interceptors.response.eject(refreshTokenInterceptor);
        };
    });

    return (<></>);
};

export default RefreshTokenHandler;