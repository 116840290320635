import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Network from "../../utils/Network";
import dayjs from "dayjs";
import { ReloadTimes } from "../../utils/constants";
import { canReload } from "../../utils/utils";
import { ConvertNetworkUser } from "../../utils/converters";
import { UserState } from "../../utils/types/redux/userTypes";
import { StatusType, StoreState } from "../../utils/types/redux/reduxTypes";

// #region Initial state
export const initialState: UserState = {
    current: {
        status: StatusType.NONE,
    }
};

// #region Reducers
export const fetchUser = createAsyncThunk('user/current/fetched', async () => {
    const response = await Network.fetchUser();
    return {
        updatedAt: dayjs().toISOString(),
        data: ConvertNetworkUser(response.data),
    };
}, {
    condition: (arg: { forceReload: boolean; }, thunkAPI) => {
        const { user } = thunkAPI.getState() as StoreState;
        return localStorage.getItem('access') !== undefined && (arg.forceReload || canReload(ReloadTimes.MEDIUM_RELOAD, user.current.updatedAt));
    }
});

// #region Slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
        update: (state, action) => {
            return {
                ...state,
                data: action.payload,
                updatedAt: dayjs().toISOString(),
                status: StatusType.FULFILLED
            };
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.current = {
                ...state.current,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED
            };
        });
        builder.addCase(fetchUser.pending, (state) => {
            state.current = {
                ...state.current,
                status: StatusType.PENDING
            };
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.current = {
                ...state.current,
                status: StatusType.REJECTED,
            };
        });
    }
});

export const { reset, update } = userSlice.actions;

export default userSlice.reducer;