import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";

const ForceUpdateModal = () => {
    return (
        <Modal
            open
            closable={false}
            maskClosable={false}
            footer={[]}
        >
            {<FormattedMessage
                defaultMessage={'A new version is available'}
            />}
            <Button onClick={() => window.location.reload()}>{'Update'}</Button>
        </Modal>
    );
};

export default ForceUpdateModal;