import { Popover } from 'antd';
import './styles.css';
import FAIcon from '../FAIcon/FAIcon';
import { ReactNode } from 'react';

interface Props {
    icon?: ReactNode;
    title: ReactNode;
    popoverTitle?: ReactNode;
    popoverContent: ReactNode;
}

const ColumnInfo = (props: Props) => {
    return (
        <div className='relative'>
            {props.title}
            <Popover
                title={props.popoverTitle}
                content={props.popoverContent}
            >
                <span className='column-info-icon'>
                    {
                        props.icon || <FAIcon prefix='fad' name='info' />
                    }
                </span>
            </Popover>
        </div>
    );
};

export default ColumnInfo;