import { Col, Row } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { ContainerTabsItemProps } from "../../../../../../../../common/ContainerTabs/ContainerTabsItem";
import { SunkhronosCompanyUsage } from "../../../../../../../../../utils/types/applications/sunkhronosTypes";
import { DictionaryString } from "../../../../../../../../../utils/types/generalTypes";
import { NivoLinechartData, NivoLinechartDataDataset } from "../../../../../../../../common/LineChart/types";
import ColumnInfo from "../../../../../../../../common/ColumnInfo/ColumnInfo";
import SpaceContent from "../../../../../../../../common/SpaceContent/SpaceContent";
import CircleButton from "../../../../../../../../common/CircleButton/CircleButton";
import FAIcon from "../../../../../../../../common/FAIcon/FAIcon";
import { TabSidebar, TabSidebarType } from "../../../../../../../../common/ContainerTabs/types";
import Title from "../../../../../../../../common/Title/Title";
import FloatingDatePickerInput from "../../../../../../../../common/Inputs/Floating/FloatingDatePicker";
import Spinner from "../../../../../../../../common/Spinner/Spinner";
import Chart from "../../../../../../../../common/LineChart/Chart";
import VirtualTable from "../../../../../../../../common/VirtualTable/VirtualTable";
import { FormattedMessage, useIntl } from "react-intl";
import getFormat from "../../../../../utils/lang";

interface Props extends ContainerTabsItemProps {
    usage: DictionaryString<SunkhronosCompanyUsage>;
    loading: boolean;
    startDate: Dayjs;
    endDate: Dayjs;
    setStartDate: (date: Dayjs) => void;
    setEndDate: (date: Dayjs) => void;
    refreshUsage: () => void;
}

interface TableSunkhronosCompanyUsage {
    year: string;
    month: string;
    data: SunkhronosCompanyUsage;
}

const Usage = (props: Props) => {
    const intl = useIntl();
    // #region State
    const [displayChart, setDisplayChart] = useState(false);
    // #endregion

    // #region Memo
    const dataSource = useMemo(() => {
        if (displayChart) {
            const dates: string[] = [];

            let tmpDate = props.startDate.clone();
            while (props.endDate.isAfter(tmpDate)) {
                dates.push(`${tmpDate.month() + 1}/${tmpDate.year()}`);
                tmpDate = tmpDate.add(1, 'month');
            }
            dates.push(`${tmpDate.month() + 1}/${tmpDate.year()}`);

            const activeUsers: NivoLinechartDataDataset[] = [];
            const users: NivoLinechartDataDataset[] = [];
            const inactiveUsers: NivoLinechartDataDataset[] = [];
            const events: NivoLinechartDataDataset[] = [];
            const messages: NivoLinechartDataDataset[] = [];
            const posts: NivoLinechartDataDataset[] = [];
            const usersWithEvents: NivoLinechartDataDataset[] = [];
            const usersWithEventClocked: NivoLinechartDataDataset[] = [];
            const plannedEvents: NivoLinechartDataDataset[] = [];
            const plannedUsersWithEvents: NivoLinechartDataDataset[] = [];

            Object.keys(props.usage).map((item, idx) => {
                const data = props.usage[item];
                activeUsers.push({ x: dates[idx], y: data.activeUsers });
                users.push({ x: dates[idx], y: data.users });
                inactiveUsers.push({ x: dates[idx], y: data.users - data.activeUsers });
                events.push({ x: dates[idx], y: data.events });
                usersWithEvents.push({ x: dates[idx], y: data.usersWithEvents });
                usersWithEventClocked.push({ x: dates[idx], y: data.usersWithEventClocked });
                messages.push({ x: dates[idx], y: data.messages });
                posts.push({ x: dates[idx], y: data.posts });
                plannedEvents.push({ x: dates[idx], y: data.plannedEvents });
                plannedUsersWithEvents.push({ x: dates[idx], y: data.plannedUsersWithEvents });
            });

            return [
                {
                    id: 'Users with event clocked',
                    key: 'users_with_event_clocked',
                    data: usersWithEventClocked
                },
                {
                    id: 'Users with event',
                    key: 'users_with_event',
                    data: usersWithEvents
                },
                {
                    id: 'Inactive users',
                    key: 'inactive_users',
                    data: inactiveUsers
                },
                {
                    id: 'Active users',
                    key: 'active_users',
                    data: activeUsers
                },
                {
                    id: 'Users',
                    key: 'users',
                    data: users
                },
                // {
                //     id: 'Planned events',
                //     data: plannedEvents
                // },
                // {
                //     id: 'Planned users with events',
                //     data: plannedUsersWithEvents
                // },
            ];
        }
        else {
            return Object.keys(props.usage).map((item) => ({ year: item.split('-')[0], month: item.split('-')[1], data: props.usage[item] }));
        }
    }, [displayChart, props.endDate, props.startDate, props.usage]);

    const columns = useMemo((): ColumnsType<TableSunkhronosCompanyUsage> => {
        const calculateRowSpan = (data: TableSunkhronosCompanyUsage[], index: number): number => {
            if (index === 0 || data[index].year !== data[index - 1].year) {
                let span = 1;
                for (let i = index + 1; i < data.length; i++) {
                    if (data[i].year === data[index].year) {
                        span++;
                    } else {
                        break;
                    }
                }
                return span;
            }
            return 0; // Hide subsequent cells
        };

        return [
            {
                key: 'year',
                dataIndex: 'year',
                title: <FormattedMessage
                    defaultMessage={'Year'}
                />,
                align: 'center',
                className: 'w-150',
                onCell: (_, index) => {
                    const rowSpan = calculateRowSpan(dataSource as TableSunkhronosCompanyUsage[], index!); // `index` is passed from Antd internally
                    return {
                        rowSpan,
                    };
                },
            },
            {
                key: 'month',
                dataIndex: 'month',
                title: <FormattedMessage
                    defaultMessage={'Month'}
                />,
                className: 'w-150',
                render: (_, record) => dayjs(record.month, 'MM').format(getFormat("MONTH"))
            },
            {
                key: 'users',
                dataIndex: 'users',
                title: <FormattedMessage
                    defaultMessage={'Users'}
                />,
                align: 'center',
                className: 'w-100',
                render: (_, record) => record.data.users
            },
            {
                key: 'events',
                dataIndex: 'events',
                title: <ColumnInfo title={<FormattedMessage defaultMessage={'Events'} />}
                    popoverContent={
                        <>
                            <p><FormattedMessage defaultMessage={'Represents the number of events in the company.'} /></p>
                            <p><FormattedMessage defaultMessage={'If the displayed month is in the future, the amount represents the number of events planned for that month. It is also displayed in orange.'} /></p>
                        </>
                    }
                />,
                align: 'center',
                className: 'w-100',
                render: (_, record) => record.data.hasUsage ? record.data.events : <span className={'usage-prevision'}>{record.data.plannedEvents}</span>
            },
            {
                key: 'usage',
                dataIndex: 'usage',
                title: <FormattedMessage
                    defaultMessage={'Usage'}
                />,
                children: [

                    {
                        key: 'activeUsers',
                        dataIndex: 'activeUsers',
                        title: <FormattedMessage
                            defaultMessage={'Active users'}
                        />,
                        align: 'center',
                        className: 'w-250',
                        render: (_, record) => record.data.activeUsers
                    },
                    {
                        key: 'usersWithEvents',
                        dataIndex: 'usersWithEvents',
                        title: <ColumnInfo title={<FormattedMessage defaultMessage={'Users with events'} />}
                            popoverContent={
                                <>
                                    <p><FormattedMessage defaultMessage={'Represents the number of users with events in the company.'} /></p>
                                    <p><FormattedMessage defaultMessage={'If the displayed month is in the future, the amount represents the number of users with events planned for that month. It is also displayed in orange.'} /></p>
                                </>
                            }
                        />,
                        align: 'center',
                        className: 'w-250',
                        render: (_, record) => record.data.hasUsage ? record.data.usersWithEvents : <span className={'usage-prevision'}>{record.data.plannedUsersWithEvents}</span>
                    },
                    {
                        key: 'usersWithEventClocked',
                        dataIndex: 'usersWithEventClocked',
                        title: <FormattedMessage
                            defaultMessage={'Users with events clocked'}
                        />,
                        align: 'center',
                        className: 'w-250',
                        render: (_, record) => record.data.usersWithEventClocked
                    },

                ]
            },
        ];
    }, [dataSource]);

    const getExtra = useMemo(() => {
        return (
            <SpaceContent>
                <CircleButton
                    icon={<FAIcon prefix='fad' name='rotate' />}
                    onClick={props.refreshUsage}
                    loading={props.loading}
                    title={intl.formatMessage({ defaultMessage: 'Refresh usage data' })}
                />
                <CircleButton
                    icon={<FAIcon prefix='fad' name='chart-line' />}
                    onClick={() => setDisplayChart(!displayChart)}
                    type={displayChart ? 'primary' : 'default'}
                    title={intl.formatMessage({ defaultMessage: 'Display chart' })}
                />
            </SpaceContent>
        );
    }, [displayChart, intl, props.loading, props.refreshUsage]);

    const haveActiveFilter = useMemo(() => {
        return (
            props.startDate !== undefined ||
            props.endDate !== undefined
        );
    }, [props.endDate, props.startDate]);

    const getSidebar = useMemo((): TabSidebar[] => {
        return [
            {
                button: {
                    icon: <FAIcon prefix='fad' name='flask-gear' />,
                    title: intl.formatMessage({ defaultMessage: 'Settings' })
                },
                hasBadge: haveActiveFilter,
                content:
                    <Row gutter={[10, 30]}>
                        <Col xs={{ span: 24 }}>
                            <Title><FormattedMessage defaultMessage={'Settings'} /></Title>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <FloatingDatePickerInput
                                rangePicker
                                label={<FormattedMessage
                                    defaultMessage={'Usage date'}
                                />}
                                picker='month'
                                value={[props.startDate, props.endDate]}
                                allowClear={false}
                                disabled={props.loading}
                                onChange={(e) => {
                                    if (e) {
                                        if (e[0])
                                            props.setStartDate(e[0].startOf('month'));
                                        if (e[1])
                                            props.setEndDate(e[1].endOf('month'));
                                    }
                                }}
                            />
                        </Col>
                    </Row>,
                type: TabSidebarType.SETTINGS
            }
        ];
    }, [haveActiveFilter, intl, props]);

    useEffect(() => {
        props.addOrUpdateExtra(getExtra, props.keyLink);
        props.addOrUpdateSidebars(getSidebar, props.keyLink);
    });
    // #endregion

    // #region Render
    return (
        <>
            {
                displayChart ?
                    props.loading ?
                        <Spinner />
                        :
                        <Chart data={dataSource as NivoLinechartData[]} />
                    :
                    <VirtualTable
                        height={320}
                        loading={{
                            spinning: props.loading,
                            indicator: <FAIcon prefix='fad' name='spinner-third' style={{ width: 50, height: 50 }} spin />
                        }}
                        columns={columns}
                        dataSource={dataSource as TableSunkhronosCompanyUsage[]}
                        pagination={false}
                        summary={(pageData) => {
                            let totalActiveUsers = 0;
                            let totalPlannedUsers = 0;
                            let totalPlannedUsersUsage = 0;
                            let totalClockedUsers = 0;
                            let totalEvents = 0;
                            let totalEventsUsage = 0;
                            let compteurUsage = 0;

                            const average = (num: number, nbrMonth = compteurUsage) => {
                                if (nbrMonth != 0) {
                                    return (num / nbrMonth).toFixed(2);
                                }
                                return 0;

                            };

                            pageData.forEach(({ data }) => {
                                totalActiveUsers += data.activeUsers;
                                totalPlannedUsers += data.plannedUsersWithEvents;
                                totalClockedUsers += data.usersWithEventClocked;
                                totalEvents += data.plannedEvents;

                                if (data.hasUsage === true) {
                                    totalPlannedUsersUsage += data.plannedUsersWithEvents;
                                    totalEventsUsage += data.plannedEvents;
                                    compteurUsage += 1;
                                }
                            });

                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className='usage-summary' style={{ fontWeight: 'bold', color: 'var(--primary)' }}>
                                        <Table.Summary.Cell index={0} align='center'>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} />
                                        <Table.Summary.Cell index={2} />
                                        <Table.Summary.Cell index={3} align='center'>{totalEventsUsage} <i style={{ color: 'var(--warning-dark)' }}>({totalEvents})</i></Table.Summary.Cell>
                                        <Table.Summary.Cell index={4} align='center'>{totalActiveUsers} <FAIcon prefix='fad' name='user' /> </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5} align='center'>{totalPlannedUsersUsage}
                                            <i style={{ color: 'var(--warning-dark)' }}> ({totalPlannedUsers}) </i><FAIcon prefix='fad' name='calendar' /></Table.Summary.Cell>
                                        <Table.Summary.Cell index={6} align='center'>{totalClockedUsers} <FAIcon prefix='fad' name='timer' /></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    <Table.Summary.Row className='usage-summary' style={{ fontWeight: 'bold', color: 'var(--primary)' }}>
                                        <Table.Summary.Cell index={0} align='center'>Average</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} />
                                        <Table.Summary.Cell index={2} />
                                        <Table.Summary.Cell index={3} align='center'>{average(totalEventsUsage)} <i style={{ color: 'var(--warning-dark)' }}>({average(totalEvents, 12)})</i></Table.Summary.Cell>
                                        <Table.Summary.Cell index={4} align='center'>{average(totalActiveUsers)} </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5} align='center'>{average(totalPlannedUsersUsage)}
                                            <i style={{ color: 'var(--warning-dark)' }}> ({average(totalPlannedUsers, 12)}) </i></Table.Summary.Cell>
                                        <Table.Summary.Cell index={6} align='center'>{average(totalClockedUsers)} </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
            }
        </>
    );
    // #endregion
};

export default Usage;