import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { ReactNode, useMemo } from 'react';
import useDevice from '../../../hooks/useSmartphone';
import { NivoLinechartData } from './types';
import './styles.css';



interface Props {
    data: NivoLinechartData[];
}


const LineChart = (props: Props) => {
    const { isSmartphone } = useDevice();

    const minValue = useMemo(() => {
        const points = props.data.flatMap(series => series.data.filter(point => Number.isInteger(point.y)).map(point => Number(point.y)));
        return Math.min(...points);
    }, [props.data]);

    const roundedMax = useMemo(() => {
        const points = props.data.flatMap(series =>
            series.data
                .filter(point => Number.isInteger(point.y))
                .map(point => Number(point.y))
        );
        const maxValue = Math.max(...points);
        const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue))); // Find the nearest power of 10
        const roundedMax = Math.ceil(maxValue / magnitude) * magnitude; // Round up to the nearest multiple of this power of 10
        return roundedMax;
    }, [props.data]);

    return (
        <div id='chart' style={{ height: 'calc(100% - 1rem)' }}>
            <ResponsiveLine
                data={props.data}
                margin={{ top: 20, right: isSmartphone ? 20 : 170, bottom: 50, left: 50 }}
                isInteractive
                tooltip={CustomTooltip}
                enableSlices={'x'}
                pointSize={10}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                pointColor={'white'}
                animate
                yScale={{
                    type: roundedMax === 0 || minValue === 0 ? 'linear' : 'log',
                    base: 10, // logarithmic base
                    min: minValue, // starting value for the logarithmic scale
                    max: roundedMax === 0 ? 10 : roundedMax, // use the calculated rounded max
                }}
                enablePointLabel={true}
                pointLabel="data.yFormatted"
                curve='monotoneX'
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                }}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 1,
                    tickRotation: 0,
                    legendOffset: 30,
                    legendPosition: 'middle',
                    truncateTickAt: 0
                }}
                legends={
                    isSmartphone ?
                        []
                        :
                        [
                            {

                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]
                }
            />
        </div>
    );
};

const CustomTooltip = (props: PointTooltipProps) => {
    return (
        <div className='custom-chart-tooltip' style={{ border: `2px solid ${props.point.borderColor}` }}>
            <strong>{props.point.data.xFormatted}</strong>
            <div>
                <strong>{props.point.serieId}: </strong>
                {props.point.data.y as ReactNode}
            </div>
        </div>
    );
};

export default LineChart;