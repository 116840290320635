import './index.css';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const LanguageMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const lang = location.pathname.split('/')[1] || 'fr';

    const handleLanguageChange = (key: string) => {
        const newPathname = `/${key}${location.pathname.slice(3)}`;
        navigate(newPathname);
    };

    const languageItems: MenuProps['items'] = [
        {
            label: 'Français',
            key: 'fr',
            onClick: () => handleLanguageChange('fr'),
        },
        {
            label: 'English',
            key: 'en',
            onClick: () => handleLanguageChange('en'),
        },
        {
            label: 'Deutsch',
            key: 'de',
            onClick: () => handleLanguageChange('de'),
        },
    ];

    return (
        <Dropdown className='marginRight' menu={{ items: languageItems }} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    {lang.toUpperCase()}
                    <DownOutlined />
                </Space>
            </a>
        </Dropdown>
    );
};

export default LanguageMenu;
