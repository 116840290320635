import { useMemo } from "react";
import CircleButton from "../../common/CircleButton/CircleButton";
import ContainerTabs from "../../common/ContainerTabs/ContainerTabs";
import { TabItem, TabSidebarType } from "../../common/ContainerTabs/types";
import FAIcon from "../../common/FAIcon/FAIcon";
import SpaceContent from "../../common/SpaceContent/SpaceContent";

const Dashboard = () => {
  const tabItems = useMemo((): TabItem[] => {
    return [
      {
        key: 'usage',
        label: 'Usage',
        children: <>{'Usage tab'}</>
      }
    ];
  }, []);

  const tabExtra = useMemo(() => (
    <SpaceContent>
      <CircleButton
        icon={<FAIcon prefix='fad' name='rotate' />}
        small
      />
    </SpaceContent>
  ), []);

  return (
    <ContainerTabs
      backUrl='/'
      tabExtra={tabExtra}
      tabItems={tabItems}
      baseUrl='dashboard'
      tabSidebars={[
        {
          button: {
            icon: <FAIcon prefix='fad' name='chart-line' />,
            title: 'dwad'
          },
          content: 'dad',
          type: TabSidebarType.FILTERS,
          titleBar: {
            title: '',
            extra: '',
            icon: ''
          }
        }
      ]
      }
    />
  );
};

export default Dashboard;