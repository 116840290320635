import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Content, Header } from "antd/es/layout/layout";
import logo from '../../../assets/logo_rectangle.png';
import { useCallback, useMemo, useState } from "react";
import { ItemType } from "antd/es/menu/interface";
import { Col, Row } from "antd/es/grid";
import { Image, Popover, Layout as ALayout, Drawer } from "antd";
import Menu from "antd/es/menu/menu";
import Avatar from "antd/es/avatar/avatar";
import './styles.css';
import Network from "../../../utils/Network";
import FAIcon from "../FAIcon/FAIcon";
import useDevice from "../../../hooks/useSmartphone";
import { reset as resetUserSlice } from "../../../store/features/userSlice";
import AppButton from "../AppButton/AppButton";
import { useIntl } from "react-intl";
import LanguageMenu from "../LanguageMenu/LanguageMenu";

const Layout = () => {
    const intl = useIntl();
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

    const location = useLocation();
    // const lang = location.pathname.split('/')[1];
    const navigate = useNavigate();
    const { isSmartphone } = useDevice();

    const resetReduxStore = useCallback(() => {
        resetUserSlice();
    }, []);

    const handleLogout = useCallback(() => {
        Network.logout().then(
            () => {
                resetReduxStore();
                // navigate(`${lang}/login`);
                navigate(`/login`);
            },
        );
    }, [navigate, resetReduxStore]);


    const accountPopoverContent = useMemo(() => {
        return (<a onClick={handleLogout}><FAIcon prefix='fad' name='right-from-bracket' /> Log out</a>);
    }, [handleLogout]);

    const tab = useMemo(() => {
        return location.pathname.length > 0 ? location.pathname.split('/')[1] : '';
    }, [location.pathname]);

    const menuItems: ItemType[] = useMemo(() => [
        {
            key: 'dashboard',
            icon: <FAIcon prefix='fad' name='house' />,
            disabled: true,
            label: intl.formatMessage({ defaultMessage: 'Dashboard' }),
        },
        {
            key: 'applications',
            icon: <FAIcon prefix='fad' name='grid-2-plus' />,
            label: intl.formatMessage({ defaultMessage: 'Applications' }),
        },
        {
            key: 'clients',
            icon: <FAIcon prefix='fad' name='user-tie-hair' />,
            disabled: true,
            label: intl.formatMessage({ defaultMessage: 'Clients' }),
        },
    ], [intl]);

    const renderMenu = useCallback((mode: 'vertical' | 'horizontal') => {
        return (
            <Menu
                mode={mode}
                style={{ width: '100%' }}
                items={menuItems}
                selectedKeys={[tab]}
                onClick={(e) => {
                    setDisplayMobileMenu(false);
                    navigate(e.key);
                }}
            />
        );
    }, [menuItems, navigate, tab]);

    const DesktopMenu = () => {
        return (
            <>
                <Col className='menu' flex='auto'>
                    {renderMenu('horizontal')}
                </Col>
                <LanguageMenu></LanguageMenu>
                <Col className='account' flex='none'>
                    <Popover
                        trigger={'click'}
                        placement='left'
                        content={accountPopoverContent}
                    >
                        <Avatar style={{ cursor: 'pointer' }} icon={<FAIcon prefix='fad' name='user' />} />
                    </Popover>
                </Col>
            </>
        );
    };

    const MobileMenu = () => {
        return (
            <Col className='mobile-menu' flex='auto'>
                <span className='toggle'><FAIcon prefix='fad' name='bars' onClick={() => setDisplayMobileMenu(true)} /></span>
                <Drawer
                    open={displayMobileMenu}
                    onClose={() => setDisplayMobileMenu(false)}
                >
                    <Row gutter={[10, 10]} style={{ flexDirection: 'column', height: '100%' }}>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', flex: 1 }}>
                            {renderMenu('vertical')}
                        </Col>
                        <Col className='account' flex='none' style={{ height: '5rem', display: 'flex', alignItems: 'center' }}>
                            {accountPopoverContent}
                        </Col>
                    </Row>
                </Drawer>
                <LanguageMenu></LanguageMenu>
            </Col>
        );
    };

    return (
        <ALayout className='layout' >
            <Header className='header'>
                <Row style={{ height: '100%' }}>
                    <Col className='logo' flex='none'><Image src={logo} preview={false} /></Col>
                    {
                        isSmartphone ?
                            <MobileMenu />
                            :
                            <DesktopMenu />
                    }
                </Row>
            </Header>
            <Content className='content'>
                <Outlet />
                <AppButton />
            </Content>
        </ALayout>
    );
};

export default Layout;