import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { NetworkJWT, NetworkResponse } from "./types/networkTypes";

export interface ApiError {
    message: string;
    status: number;
    version: string;
}

class API {
    private axiosInstance: AxiosInstance;

    constructor(baseURL: string) {
        this.axiosInstance = axios.create({
            baseURL,
            timeout: 10000,
        });
        this.axiosInstance.defaults.headers['X-Version'] = import.meta.env.VITE_APP_VERSION;
        this.axiosInstance.defaults.headers['X-App'] = import.meta.env.VITE_APP_NAME;

        // Add an interceptor for request headers
        this.axiosInstance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                const token = localStorage.getItem('access');
                if (token) {
                    config.headers = config.headers || {};
                    config.headers.Authorization = `Bearer ${token}`;
                }
                config.withCredentials = true;
                return config;
            },
            (error: AxiosError) => {
                return Promise.reject(error);
            }
        );
    }

    // HTTP methods with types
    async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.get<T>(url, config);
        return response.data;
    }

    async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.post<T>(url, data, config);
        return response.data;
    }

    async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.put<T>(url, data, config);
        return response.data;
    }

    async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.delete<T>(url, config);
        return response.data;
    };

    // Authentication methods
    async login(username: string, password: string) {
        return this.post<NetworkResponse<NetworkJWT>>('auth/login', { username, password }, { withCredentials: true })
            .then((res) => {
                const accessToken = res.data.access;
                if (accessToken) {
                    localStorage.setItem('access', accessToken);
                }
            });
    };

    // Refresh authentication: Obtain a new access token using the refresh token
    async refreshAuth() {
        return this.post<NetworkResponse<NetworkJWT>>('auth/refresh', null, { withCredentials: true });
    };

    // Logout request: Clear session and invalidate tokens
    async logout() {
        return this.post<NetworkResponse<NetworkJWT>>('auth/logout', null, { withCredentials: true })
            .then(
                () => localStorage.removeItem('access'),
                () => localStorage.removeItem('access')
            );
    };

    public get axios() {
        return this.axiosInstance;
    }

    public get interceptors() {
        return this.axiosInstance.interceptors;
    }
}

export default new API(import.meta.env.VITE_APP_BACK_BASE_URL + '/api');
