import { useCallback, useEffect, useMemo, useState } from "react";
import { ExternalCompany } from "../../../../../utils/types/clientTypes";
import Card from "../../../../common/Card/Card";
import FAIcon from "../../../../common/FAIcon/FAIcon";
import CircleButton from "../../../../common/CircleButton/CircleButton";
import { Col, Divider, Row, Select, Space } from "antd";
import { Invoice } from "../../../../../utils/types/applications/invoiceTypes";
import Network from "../../../../../utils/Network";
import { showNotification } from "../../../../../utils/utils";
import { ConvertNetworkInvoices } from "../../../../../utils/converters";
import VirtualTable from "../../../../common/VirtualTable/VirtualTable";
import { ColumnsType } from "antd/es/table";
import useDevice from "../../../../../hooks/useSmartphone";
import { FormattedMessage, useIntl } from "react-intl";
import getFormat from "../../../Applications/Sunkhronos/utils/lang";

interface Props {
    externalCompanies?: ExternalCompany[];
}

const Invoices = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [selectedExternalCompany, setSelectedExternalCompany] = useState<ExternalCompany | undefined>(props.externalCompanies ? props.externalCompanies[0] : undefined);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const { isSmartphone } = useDevice();
    const intl = useIntl();

    const getInvoices = useCallback(() => {
        if (selectedExternalCompany === undefined) {
            return;
        }

        setLoading(true);
        Network.fetchInvoices(selectedExternalCompany.id).then(
            (res) => {
                setInvoices(ConvertNetworkInvoices(res.data, props.externalCompanies));
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occured while retrieving the invoices' }), 'error');
            }
        );
    }, [intl, props.externalCompanies, selectedExternalCompany]);

    useEffect(() => {
        if (props.externalCompanies && props.externalCompanies.length > 0 && selectedExternalCompany === undefined) {
            setSelectedExternalCompany(props.externalCompanies[0]);
        }
    }, [props.externalCompanies, selectedExternalCompany]);

    useEffect(() => {
        getInvoices();
    }, [getInvoices, selectedExternalCompany]);

    const columns = useMemo((): ColumnsType<Invoice> => [
        {
            key: 'created',
            dataIndex: 'created',
            title: intl.formatMessage({ defaultMessage: 'Created on' }),
            className: 'w-150',
            align: 'center',
            render: (_, record) => record.created.format(getFormat('DATE'))
        },
        {
            key: 'date',
            dataIndex: 'date',
            title: intl.formatMessage({ defaultMessage: 'Invoice date' }),
            className: 'w-300',
            children: [
                {
                    key: 'startDate',
                    dataIndex: 'startDate',
                    title: intl.formatMessage({ defaultMessage: 'From' }),
                    className: 'w-150',
                    align: 'center',
                    render: (_, record) => record.startDate.format(getFormat('DATE'))
                },
                {
                    key: 'endDate',
                    dataIndex: 'endDate',
                    className: 'w-150',
                    title: intl.formatMessage({ defaultMessage: 'Until' }),
                    align: 'center',
                    render: (_, record) => record.endDate.format(getFormat('DATE'))
                },
            ]
        },
        {
            key: 'total',
            dataIndex: 'total',
            title: intl.formatMessage({ defaultMessage: 'Total' }),
            className: 'w-300',
            render: (_, record) => `${record.amount} CHF`
        },
    ], [intl]);

    return (
        <Card
            icon={<FAIcon prefix='fad' name='file-invoice' />}
            title={<FormattedMessage defaultMessage={"Invoices"} />}
            headerElements={[
                <Space>
                    {
                        !isSmartphone &&
                        <>
                            <Select
                                value={selectedExternalCompany?.id}
                                options={props.externalCompanies?.map((ec) => ({ value: ec.id, label: ec.title }))}
                                onChange={(e) => {
                                    setSelectedExternalCompany(props.externalCompanies?.find((ec) => e === ec.id));
                                }}
                                style={{ minWidth: '200px' }}
                            />
                            <Divider type='vertical' />
                        </>
                    }
                    <CircleButton
                        small
                        icon={<FAIcon prefix='fad' name='rotate' />}
                        loading={loading}
                        onClick={getInvoices}
                    />
                    <CircleButton
                        small
                        disabled
                        icon={<FAIcon prefix='far' name='plus' />}
                    />
                </Space>
            ]}
        >
            <Row gutter={[10, 10]}>
                {
                    isSmartphone &&
                    <Col xs={{ span: 24 }}>

                        <Select
                            value={selectedExternalCompany?.id}
                            options={props.externalCompanies?.map((ec) => ({ value: ec.id, label: ec.title }))}
                            onChange={(e) => {
                                setSelectedExternalCompany(props.externalCompanies?.find((ec) => e === ec.id));
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                }
                <VirtualTable
                    dataSource={invoices}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                />
            </Row>
        </Card>
    );
};

export default Invoices;